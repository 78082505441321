<template>
  <div
    ref="container"
  />
</template>
<script>
// style="min-width = 600px; max-width: 600px; min-height: 400px; max-height: 400px; height: 400px;"
import { mapGetters } from 'vuex';
import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';
import DashboardStackPlotlyWidget from '@/dashboard/components/DashboardStackPlotlyWidget';
import DashboardStackLeafletWidget from '@/dashboard/components/DashboardStackLeafletWidget';
import DashboardStackVueWidgetAdapter from '@/dashboard/components/DashboardStackVueWidgetAdapter';
import InstrumentTableVueWidget from '@/dashboard/components/widgets/InstrumentTableVueWidget.vue';
import LeafletVueWidget from '@/dashboard/components/widgets/LeafletVueWidget.vue';
import { onExportExcel, onSensorQueryDebug } from './DashboardWidgetExport';

function VisualisationTypeIs(settings, x) {
  return settings
        && settings.user
        && settings.user.visualisationType
        && settings.user.visualisationType === x;
}

export default {
  props: {
    settings: {
      type: Object,
      default: () => (null),
      required: false,
    },
    height: {
      type: Number,
      default: () => (900),
      required: false,
    },
    legacyMap: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data: () => ({
    dashboardId: null,
    widgetId: null,
    grid: null,
    widget: null,
    thisInstance: null,
  }),
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),
  },
  watch: {
    async tenantId() {
      if (this.settings) {
        await this.thisInstance.instance
          .displayWidget();
      }
    },
    async accessGroupIds(newVal, oldVal) {
      if (this.settings && oldVal.join() !== newVal.join()) {
        await this.thisInstance.instance
          .displayWidget();
      }
    },
    async timezone() {
      if (this.settings) {
        await this.thisInstance.instance
          .displayWidget();
      }
    },
    async settings() {
      if (this.thisInstance) {
        if (this.settings) {
          this.widget.WidgetSettings = JSON.stringify(this.settings);
          await this.thisInstance.instance.displayWidget();
        }
      }
    },
  },
  async mounted() {
    this.dashboardId = crypto.randomUUID();
    const gridId = `grid-stack-${this.dashboardId}`;
    const gridEl = this.$refs.container;
    gridEl.classList.add('grid-stack');
    gridEl.id = gridId;
    this.grid = {
      DashboardId: this.dashboardId,
      grid: GridStack.init({
        margin: 0,
        cellHeight: 1,
        column: 12,
      }, gridId),
      container: gridEl,
    };

    const gridItemContentEl = gridEl.closest('.grid-stack-item-content');
    const gridItemHeight = gridItemContentEl ? gridItemContentEl.clientHeight : 0;

    this.widgetId = crypto.randomUUID();
    const gsw = this.grid.grid.addWidget({
      w: 12,
      h: Math.max(this.height, gridItemHeight),
      id: this.widgetId,
      content: '',
    });

    this.widget = {
      DashboardId: this.dashboardId,
      DashboardsWidgetId: this.widgetId,
      DatasourceSettings: '',
      WidgetSettings: JSON.stringify(this.settings),
      WidgetId: this.widgetId,
      Enabled: true,
      Visible: true,
      AxisX: Number(gsw.getAttribute('gs-x')),
      AxisY: Number(gsw.getAttribute('gs-y')),
      AxisWidth: Number(gsw.getAttribute('gs-w')),
      AxisHeight: Number(gsw.getAttribute('gs-h')),
      AxisLock: false,
    };

    const { grid } = this.grid;
    const iface = {
      getStore: () => (this.$store),
      getRouter: () => (this.$router),
      getTimeZone: () => (this.timezone),
      editModeFlag: () => (false),
      cellHeight: () => (1),
      selectedInstruments: () => ([]),
      clearResizeEventFlag: () => { },
      updateActiveWidget: (/* widgetId */) => { },
      removeWidget: (/* widgetId */) => { },
      getVueParent: () => (this), // required by DashboardStackVueWidgetAdapter
      refreshWidget: () => { this.refreshWidget(); },
      onExportExcel: () => { onExportExcel(this.widget); },
      onSensorQueryDebug: () => { onSensorQueryDebug(this.widget); },
    };

    if (VisualisationTypeIs(this.settings, 'leaflet')) {
      if (this.legacyMap) {
        this.thisInstance = {
          widget: this.widget,
          grid,
          instance: new DashboardStackLeafletWidget(grid, this.widget, iface),
        };
      } else {
        this.thisInstance = {
          widget: this.widget,
          grid,
          instance: new DashboardStackVueWidgetAdapter(grid, this.widget, iface, LeafletVueWidget),
        };
      }
    } else if (VisualisationTypeIs(this.settings, 'table+instrument')) {
      this.thisInstance = {
        widget: this.widget,
        grid,
        instance: new DashboardStackVueWidgetAdapter(grid, this.widget, iface, InstrumentTableVueWidget),
      };
    } else {
      this.thisInstance = {
        widget: this.widget,
        grid,
        instance: new DashboardStackPlotlyWidget(grid, this.widget, iface),
      };
    }

    if (this.settings) {
      await this.thisInstance.instance
        .displayWidget();
    }
  },
  methods: {
    // This refreshes a widget
    async refreshWidget() {
      await this.thisInstance.instance
        .displayWidget();
    },
  },
};
</script>

<style>

</style>
