var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"full-height"},[_c('div',{staticClass:"full-height pa-0 remove-certain-borders",style:({
        width: _vm.showInstrumentList ? '75%' : _vm.isHovering ? '91%' : '96.5%',
        transition: 'width 0.3s ease-in-out',
      })},[_c('LeafletVueWidget',{style:({
          height: _vm.computedHeight + 'px',
        }),attrs:{"widget-settings":_vm.widgetSettings ? _vm.widgetSettings : _vm.mapSettings,"widget-adapter":_vm.widgetAdapter,"edit-mode-flag":_vm.editModeFlag}})],1),_c('div',{staticClass:"pa-0 no-left-border-vbtn",style:({
        width: _vm.showInstrumentList ? '25%' : _vm.isHovering ? '9%' : '3.5%',
        transition: 'width 0.3s ease-in-out',
        overflow: 'hidden',
        height: _vm.computedHeight + 'px',
      }),on:{"mouseenter":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false}}},[(!_vm.showInstrumentList)?_c('div',[_c('v-btn',{staticClass:"vertical-text no-left-border-vbtn",style:({
            height: _vm.computedHeight + 'px',
            width: '100%',
            backgroundColor: '#272727',
            color: 'white',
            textAlign: _vm.isHovering ? 'center' : 'left',
            transition: 'all 0.3s ease-in-out',
          }),attrs:{"outlined":""},on:{"click":_vm.toggleInstrumentList}},[_vm._v(" "+_vm._s(_vm.$t('Analytics.InstrumentMapView.ShowInstrumentList'))+" ")])],1):_vm._e(),(_vm.showInstrumentList)?_c('div',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"no-left-border-vbtn",staticStyle:{"background-color":"#272727","color":"white"},attrs:{"width":"100%","outlined":""},on:{"click":_vm.toggleInstrumentList}},[_vm._v(" "+_vm._s(_vm.$t('Analytics.InstrumentMapView.HideInstrumentList'))+" ")]),_c('v-slide-x-transition',[(_vm.showInstrumentList)?_c('div',[_c('InstrumentTableVueWidget',{staticClass:"dynamic-height",style:({ '--computed-height': _vm.computedHeight + 'px' }),attrs:{"widget-settings":_vm.instrumentListSettings,"widget-adapter":_vm.widgetAdapter}})],1):_vm._e()])],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }