import workers from './workers/workers.store';
import modem from './modem/modem.store';
import modemFileTree from './modemFileTree/modemFileTree.store';
import workerconfigs from './workerConfigurations/workerconfigs.store';

export default {
  namespaced: true,
  modules: {
    modem,
    modemFileTree,
    workers,
    workerconfigs,
  },
};
