<template>
  <div
    class="fill-height"
    style="overflow: hidden;"
  >
    <v-dialog
      v-model="showDialog"
      transition="dialog-bottom-transition"
      width="100%"
      persistent
      style="z-index: 9999"
      @click:outside="clickOutside"
    >
      <v-card>
        <v-card-title>
          View {{ viewData ? viewData.InstrumentName : '' }} / {{ viewData ? viewData.Name : '' }}
          <v-spacer />
          <v-btn
            @click="clickOutside"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-title>
        <v-card-text style="height: calc(100% - 62px)">
          <v-container
            fluid
            style="height: 100%"
          >
            <v-row style="height: 100%">
              <v-col
                lg="4"
              >
                <time-range-card
                  v-models:periodicity="form.periodicity"
                  v-models:dateRangeFrom="form.dateRangeFrom"
                  v-models:dateRangeTo="form.dateRangeTo"
                  v-models:lastnScale="form.lastnScale"
                  v-models:lastnPeriod="form.lastnPeriod"
                  v-models:dateList="form.dateList"
                  :show="(true)"
                  :show-record="(false)"
                  :sensor-id-list="sensorIdList"
                  :vector-id-list="vectorIdList"
                  :selected-sensors="form.selectedSensors"
                />
              </v-col>
              <v-col
                lg="8"
              >
                <div>
                  <dashboard-stack-single-widget-host
                    v-if="showViewHost"
                    :settings="viewSettings"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ag-grid-vue
      style="width: 100%; height: 100%; border-radius: 12px; overflow: hidden;"
      class="ag-theme-balham-dark"
      :column-defs="columnDefs"
      :row-data="rowData"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';
import datehandling from '@/components/datehandling';
import importal from '@/api/importal';
import ViewButtonCellRenderer from '@/components/ViewButtonCellRenderer.vue';
import DashboardStackSingleWidgetHost from '@/dashboard/components/DashboardStackSingleWidgetHost.vue';
import DashboardStackVueWidgetAdapter from '../DashboardStackVueWidgetAdapter';
import TimeRangeCard from '../graphform/TimeRangeCard.vue';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default {
  name: 'InstrumentTableVueWidget',
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ViewButtonCellRenderer,
    TimeRangeCard,
    DashboardStackSingleWidgetHost,
  },
  props: {
    widgetSettings: {
      type: Object,
      default: () => {},
      required: true,
    },
    widgetAdapter: {
      type: DashboardStackVueWidgetAdapter,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      LastPicker: ['Years', 'Months', 'Days', 'Hours', 'Minutes'],
      LastPicker2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE'],
      AggregateByItems: ['Any', 'Average', 'Min', 'Max', 'Sum'],
      AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],
      AggregateForEveryItems: ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
      AggregateForEvery2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],

      showDialog: false,
      showViewHost: false,
      columnDefs: null,
      editModeFlag: false, // Directly mutated by the adapter
      widgetSize: null, // Directly mutated by the adapter
      rowData: [],
      viewData: null,

      form: {
        periodicity: 'last_n',
        dateRangeFrom: null,
        dateRangeTo: null,
        lastnScale: 'Days',
        lastnPeriod: 15,
        dateList: [],

        selectedSensors: [],
      },
    };
  },
  computed: {
    ...mapState('instrument', ['selectedInstruments']),
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    viewSettings() {
      if (this.form.selectedSensors.length > 0) { return this.buildViewSettings(); }
      return null;
    },
    sensorIdList() {
      return this.form.selectedSensors
        .filter((x) => x.T === 'S')
        .map((x) => x.Id);
    },
    vectorIdList() {
      return this.form.selectedSensors
        .filter((x) => x.T === 'V')
        .map((x) => ([x.Id, x.Name]));
    },
  },
  watch: {
    selectedInstruments(value) {
      this.loadRowData(value);
    },
  },
  created() {
    this.loadRowData = debounce(this.loadRowData);
  },
  beforeMount() {
    this.columnDefs = [
      {
        field: 'InstrumentName',
        width: 140,
        resizable: true,
        filter: true,
        sortable: true,
      },
      {
        field: 'Name',
        width: 95,
        resizable: true,
        filter: true,
        sortable: true,
      },
      {
        field: 'LastValue',
        headerName: 'Last Value',
        width: 100,
        resizable: true,
        filter: true,
        sortable: false,
      },
      {
        field: 'SensorId',
        headerName: 'View',
        width: 55,
        resizable: true,
        cellRenderer: 'ViewButtonCellRenderer',
        cellRendererParams: {
          clicked: (event) => {
            this.onView(event.api, event.data);
          },
        },
      },
    ];
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onView(api, data) {
      this.viewData = data;
      this.form.selectedSensors = [data]
        .map((x) => ({
          T: 'S',
          Id: x.SensorId,
          Name: x.Name,
          InstrumentName: x.InstrumentName,
        }));
      this.showDialog = true;
      setTimeout(() => {
        this.showViewHost = true;
      }, 350);
    },
    clickOutside() {
      this.showDialog = false;
    },

    async loadRowData(value) {
      const instruments = value
        .map((x) => `I=${x.InstrumentId}`)
        .join('&');
      await importal.get(`DevSensorList?${instruments}`)
        .then((resp) => (resp.data))
        .then((data) => {
          this.rowData = data;
        });
      const formulas = [];
      // eslint-disable-next-line camelcase
      const sensor_selection = [];
      // eslint-disable-next-line camelcase
      const input_filter = {
        last_n: {
          scale: 'YEAR',
          period: 15,
        },
      };
      // eslint-disable-next-line camelcase
      const input_time_axis = {
        round_to: {
          scale: 'MINUTE',
          multiplier: 1,
          divisor: 1,
        },
      };
      // eslint-disable-next-line camelcase
      const output_time_axis = {
        round_to: {
          scale: 'NONE',
          multiplier: 1,
          divisor: 1,
        },
      };
      await Promise.all(this.rowData.map(async (row) => {
        formulas.push({
          symbol: 's'.concat(row.SensorId.toString()),
          formula_text: '=s'.concat(row.SensorId.toString()).concat(';'),
          output_aggregation: 'AVG',
        });
        sensor_selection.push({
          symbol: 's'.concat(row.SensorId.toString()),
          sensor_id: row.SensorId,
          input_aggregation: 'AVG',
        });
      }));

      const widgetSettings = {
        formulas,
        // eslint-disable-next-line camelcase
        input_filter: [input_filter],
        input_time_axis,
        output_time_axis,
        sensor_selection,
        vector_selection: [],
      };

      const data = await (await importal.post('SensorLastValue', widgetSettings)).data;
      if (data) {
        this.rowData = this.rowData.map((row) => {
          // Find the corresponding column in the data datatable
          const columnName = `s${row.SensorId}`;
          const matchingColumn = data.Columns.findIndex((col) => col.ColumnName === columnName);

          // Add the LastValue property if a match is found
          return {
            ...row,
            LastValue: data.Rows[0][matchingColumn] ? data.Rows[0][matchingColumn] : null, // Add the value
          };
        });
      }
    },

    buildViewSettings() {
      const query = {
        user: {
          timezone: this.timezone,
        },
        data: {
          formulas: [],
          input_filter: [],
          input_time_axis: {
            round_to: {
              scale: 'NONE',
              multiplier: 1,
              divisor: 1,
            },
          },
          output_time_axis: {
            round_to: {
              scale: 'NONE',
              multiplier: 1,
              divisor: 1,
            },
          },
          sensor_selection: [],
          vector_selection: [],
          timezone: this.timezone,
          output_in_local: false,
        },
        visualization: {
          alias: [],
          graphType: 'scatter',
          graphStyle: 'lines',
          graphOptions: {
            x_axis: [],
            y_axis: [],
            groupby: [],
          },
        },
      };

      query.visualization.graphOptions.x_axis.push({
        ts: 'value',
      });
      query.user.selectedSensors = this.form.selectedSensors;
      query.data.formulas = query.data.formulas.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => ({
            symbol: `s${x.Id}`,
            formula_text: `=s${x.Id};`,
            output_aggregation: x.Aggregation || 'ANY',
          })),
      );
      query.data.sensor_selection = (this.form.selectedSensors
        .filter((x) => x.T === 'S')
        .map((x) => ({
          symbol: `s${x.Id}`,
          sensor_id: x.Id,
          input_aggregation: x.Aggregation || 'ANY',
        }))
      );
      query.visualization.alias = query.visualization.alias.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => (JSON.parse(`{"s${x.Id}": "${x.InstrumentName}.${x.DisplayName || x.Name}"}`))),
      );

      query.visualization.graphOptions.y_axis = query.visualization.graphOptions.y_axis.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => (JSON.parse(`{"s${x.Id}": "value"}`))),
      );

      query.data.input_time_axis.round_to.scale = 'NONE';
      query.data.input_time_axis.round_to.multiplier = 1;
      query.data.input_time_axis.round_to.divisor = 1;

      query.data.output_time_axis.round_to.scale = 'NONE';
      query.data.output_time_axis.round_to.multiplier = 1;
      query.data.output_time_axis.round_to.divisor = 1;

      if (this.form.periodicity === 'last_n') {
        query.data.input_filter.push({
          last_n: {
            scale: this.LastPicker2Query[this.LastPicker.indexOf(this.form.lastnScale)],
            period: Number(this.form.lastnPeriod),
          },
        });
      } else if (this.form.periodicity === 'time_range') {
        query.data.input_filter.push({
          time_range: {
            start: datehandling.formatForApi(this.form.dateRangeFrom),
            end: datehandling.formatForApi(this.form.dateRangeTo),
          },
        });
      } else if (this.form.periodicity === 'time_list') {
        query.data.input_filter.push({
          time_list: {
            dates: this.form.dateList.map((x) => datehandling.formatForApi(x)),
          },
        });
      }

      query.data.output_in_local = true;

      return query;
    },
  },
};
</script>
