/* eslint-disable class-methods-use-this */
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import FileButtonMenu from '../components/FileButtonMenu.vue';
// import importal from '@/api/importal';

export default class GsFilesTree {
  constructor(vue) {
    this.vue = vue;
  }

  createFileItem(params) {
    const container = document.createElement('div');
    const items = [
      { title: i18n.t('Worker.ModemFileTree.Edit'), action: () => this.vue.editFile(params.data.name) },
      { title: i18n.t('Worker.ModemFileTree.Rename'), action: () => this.vue.renameItem(params.data.name, params.data.type) },
      { title: i18n.t('Worker.ModemFileTree.Delete'), action: () => this.vue.deleteItem(params.data.name, params.data.type) },
    ];
    const ButtonMenuConstructor = Vue.extend(FileButtonMenu);
    const instance = new ButtonMenuConstructor({
      vuetify: this.vue.$root.$options.vuetify, // Pass Vuetify instance
      propsData: { items, text: params.data.name, icon: 'mdi-file-document' },
    });
    instance.$mount();
    container.appendChild(instance.$el);
    return instance.$el;
  }

  createText(params) {
    const text = document.createElement('span');
    text.textContent = params.data.name;
    text.style.textTransform = 'none';
    text.style.fontSize = 'inherit';
    text.style.fontWeight = 'inherit';
    return text;
  }

  createFolderItem() {
    const button = document.createElement('button');
    button.classList.add('v-btn', 'text');
    button.setAttribute('type', 'button');
    button.style.fontSize = 'inherit';
    button.style.fontWeight = 'inherit';
    button.style.textTransform = 'none';
    return button;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'Action',
          headerName: i18n.t('Worker.ModemFileTree.Actions'),
          resizable: false,
          width: 90,

          cellRenderer: (params) => {
            const container = document.createElement('div');

            if (params.data.type === 'file') {
              const editIcon = document.createElement('i');
              editIcon.className = 'v-icon mdi mdi-pencil';
              editIcon.title = i18n.t('Worker.ModemFileTree.Edit');
              editIcon.style.marginRight = '10px';
              editIcon.style.cursor = 'pointer';
              editIcon.addEventListener('click', () => { this.vue.editFile(params.data.name); });
              container.appendChild(editIcon);

              const renameIcon = document.createElement('i');
              renameIcon.className = 'v-icon mdi mdi-rename-box';
              renameIcon.title = i18n.t('Worker.ModemFileTree.Rename');
              renameIcon.style.marginRight = '10px';
              renameIcon.style.cursor = 'pointer';
              renameIcon.addEventListener('click', () => { this.vue.renameItem(params.data.name, params.data.type); });
              container.appendChild(renameIcon);
            }

            return container;
          },
        },
        {
          headerName: this.vue.$t('Worker.ModemFileTree.Filename'),
          field: 'name',
          filter: true,
          sortable: true,
          editable: false,
          width: 500,

          cellRenderer: (params) => {
            const createIcon = () => {
              const icon = document.createElement('span');
              icon.style.marginRight = '10px';
              icon.style.cursor = 'pointer';
              return icon;
            };

            let item;
            let icon;
            switch (params.data.type) {
              case 'file':
                item = this.createFileItem(params);
                break;
              case 'folder':
                item = this.createFolderItem();
                item.addEventListener('click', () => { this.vue.changePath(params.data.name); });
                icon = createIcon();
                icon.className = 'v-icon mdi mdi-folder x-small';
                item.appendChild(icon);
                item.appendChild(this.createText(params));
                break;
              default:
                item = this.createFolderItem();
                icon = createIcon();
                icon.className = 'v-icon mdi mdi-file-document x-small';
                item.appendChild(icon);
                item.appendChild(this.createText(params));
            }

            const container = document.createElement('div');
            container.appendChild(item);
            return container;
          },
        },
        {
          headerName: this.vue.$t('Worker.ModemFileTree.SavedTime'),
          field: 'saved_time',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          field: 'Delete',
          headerName: i18n.t('Worker.ModemFileTree.Delete'),
          resizable: false,
          width: 60,

          cellRenderer: (params) => {
            const container = document.createElement('div');

            const deleteIcon = document.createElement('i');
            deleteIcon.className = 'v-icon mdi mdi-delete-forever';
            deleteIcon.title = i18n.t('Worker.ModemFileTree.Delete');
            deleteIcon.style.cursor = 'pointer';
            deleteIcon.addEventListener('click', () => { this.vue.deleteItem(params.data.name, params.data.type); });

            container.style.textAlign = 'center';
            container.appendChild(deleteIcon);
            return container;
          },
        },
      ]);
    });
  }
}
