/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsJournal extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          headerName: this.vue.$t('Worker.Modem.ModemId'),
          field: 'CommEquipmentUid',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.Modem.SubsystemName'),
          field: 'SubsystemName', // Match `journalData` field names here
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.Modem.ExitCode'),
          field: 'ReturnCode',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.Modem.Timestamp'),
          field: 'TimeStamp',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.Modem.LogFileContent'),
          field: 'LogFileContent',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          field: 'Actions',
          headerName: this.vue.$t('Alert.Manage.AlertList.Actions'),
          resizable: true,
          width: 120,

          cellRenderer: (params) => {
            const container = document.createElement('div');

            const infoIcon = document.createElement('i');
            infoIcon.className = 'v-icon mdi mdi-eye';
            infoIcon.title = this.vue.$t('Worker.Modem.SeeLog');
            infoIcon.style.marginRight = '10px';
            infoIcon.style.cursor = 'pointer';
            infoIcon.addEventListener('click', () => {
              this.vue.onSeeLog(params.data);
            });

            container.appendChild(infoIcon);
            return container;
          },
        },
      ]);
    });
  }
}
