<template>
  <div>
    <v-card-title class="px-3 pt-3 pb-0">
      <p class="my-0">
        {{ $t(graphText) }}
      </p>
    </v-card-title>
    <v-card-title class="px-3 pt-0 pb-3 mb-3">
      <p class="my-0">
        {{ $t('Dashboard.VisualizationSetting.VisualizationSetting') }}
      </p>
    </v-card-title>
    <v-row class="px-4">
      <v-col>
        <v-slider
          v-model="cluster"
          :max="255"
          :min="1"
          :label="$t('Analytics.InstrumentMapView.ClusterRangeSize')"
          class="align-center"
        >
          <template #append>
            <v-text-field
              v-model="cluster"
              class="mt-0 pt-0"
              type="number"
              style="width: 30px"
            />
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-slider
          v-model="tickSize"
          :tick-labels="ticksLabels"
          :max="48"
          :min="12"
          step="12"
          ticks="always"
          tick-size="6"
          :label="$t('Analytics.InstrumentMapView.IconSize')"
          class="align-center"
        />
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col
        cols="4"
        class="d-flex align-center"
      >
        <label>{{ $t('Analytics.InstrumentMapView.DefaultMap') }}</label>
      </v-col>
      <v-col
        cols="8"
      >
        <v-select
          v-model="defaultMapViewSelector"
          :items="mapViews"
          :placeholder="$t('Analytics.InstrumentMapView.MapView')"
          item-text="Name"
          return-object
          style="max-width: 250px;"
        />
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col
        cols="4"
        class="d-flex align-center"
      >
        <label>{{ $t('Analytics.InstrumentMapView.DefaultLayer') }}</label>
      </v-col>
      <v-col
        cols="8"
      >
        <v-select
          v-model="defaultLayerName"
          :items="layerOptions"
          :placeholder="$t('Analytics.InstrumentMapView.Layer')"
          item-text="layerGroup"
          return-object
          style="max-width: 250px;"
        />
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        class="mr-4"
        @click="cancel"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn @click="save">
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  props: {
    visualisation: {
      type: String,
      default: () => null,
      required: true,
    },
    graphType: {
      type: String,
      default: () => null,
      required: true,
    },
    graphStyle: {
      type: String,
      default: () => null,
      required: true,
    },
    graphText: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data: () => ({
    model: {
      widgetId: null,
      enabled: true,
      visible: true,
      axisWidth: 4,
      axisHeight: 200,
      axisX: 0,
      axisY: 0,
      axisLock: false,
    },
    cluster: 80,
    tickSize: 36,
    form: {
    },
    defaultLayerName: null,
    defaultMapViewSelector: null,
    baseMapViews: [
      {
        Name: 'World',
        CRS: 'EPSG3857',
        TileLayers: [
          {
            layerGroup: 'ESRI World Imagery',
            urlTemplate: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            options: {
              attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            },
          },
          {
            layerGroup: 'Open Street Map',
            urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            options: {
              attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
              className: 'map-tiles',
            },
          },
        ],
        bounds: {
          northEast: { lat: 90, lng: 180 },
          southWest: { lat: -90, lng: -180 },
        },
      },
    ],
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapState('map', ['baseLayers']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['pleaseWait']),

    isNew() {
      return this.activeWidget === undefined;
    },
    jsonWidgetSettings() {
      const query = {
        user: {
          mapOptions: {
            iconsize: this.tickSize,
            cluster: this.cluster,
            defaultMap: this.defaultMapViewSelector?.Name || null,
            defaultLayer: this.defaultLayerName?.layerGroup || null,
          },
        },
      };
      return JSON.stringify(query);
    },

    ticksLabels() {
      return [
        this.$t('Analytics.InstrumentMapView.Tiny'),
        this.$t('Analytics.InstrumentMapView.Small'),
        this.$t('Analytics.InstrumentMapView.Medium'),
        this.$t('Analytics.InstrumentMapView.Large'),
      ];
    },
    mapViews() {
      const rc = this.baseMapViews.concat(this.baseLayers); // Create all views
      return rc;
    },
    layerOptions() {
      // Check if defaultMapViewSelector is selected
      if (this.defaultMapViewSelector) {
        const selectedMapView = this.mapViews.find(
          (view) => view.Name === this.defaultMapViewSelector.Name,
        );
        if (selectedMapView) {
          if (selectedMapView.TileLayers.length > 0) {
            return selectedMapView.TileLayers;
          }
          return selectedMapView.ImageOverlays;
        }
      }
      return [];
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.clearWidgetSettings();
      if (!this.isNew) {
        this.loadWidgetSettings();
      }
    },
    clearWidgetSettings() {
    },
    loadWidgetSettings() {
      const widgetSettings = JSON.parse(this.activeWidget.WidgetSettings);
      if (widgetSettings.user && widgetSettings.user.mapOptions) {
        this.tickSize = widgetSettings.user.mapOptions.iconsize;
        this.cluster = widgetSettings.user.mapOptions.cluster;

        // Locate the default map view by name
        const mapViewName = widgetSettings.user.mapOptions.defaultMap;
        this.defaultMapViewSelector = this.mapViews.find(
          (view) => view.Name === mapViewName,
        ) || null;

        // Locate the default layer by layerGroup
        const layerGroup = widgetSettings.user.mapOptions.defaultLayer;
        this.defaultLayerName = this.layerOptions.find(
          (layer) => layer.layerGroup === layerGroup,
        ) || null;
      }
    },
    cancel() {
      this.$emit('cancel', this.isNew);
    },
    save() {
      if (this.isNew) {
        this.create();
      } else {
        this.saveWidgetSettings();
      }
    },
    create() {
      this.$emit('create', {
        DashboardsWidgetId: this.activeWidgetId,
        DatasourceSettings: '', // TODO check if this property is useful, it seems that WidgetSettings do it all
        WidgetSettings: this.jsonWidgetSettings,
        WidgetId: this.model.widgetId,
        Enabled: this.model.enabled,
        Visible: this.model.visible,
        AxisHeight: this.model.axisHeight,
        AxisWidth: this.model.axisWidth,
        AxisY: this.model.axisY,
        AxisX: this.model.axisX,
        AxisLock: this.model.axisLock,
      });
    },
    saveWidgetSettings() {
      this.$emit('save', {
        DashboardsWidgetId: this.activeWidgetId,
        WidgetSettings: this.jsonWidgetSettings,
      });
    },
  },
};
</script>

<style>
</style>
