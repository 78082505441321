<template>
  <v-container fluid>
    <v-row class="full-height">
      <!-- Main content -->
      <div
        :style="{
          width: showInstrumentList ? '75%' : isHovering ? '91%' : '96.5%',
          transition: 'width 0.3s ease-in-out',
        }"
        class="full-height pa-0 remove-certain-borders"
      >
        <LeafletVueWidget
          :style="{
            height: computedHeight + 'px',
          }"
          :widget-settings="widgetSettings ? widgetSettings : mapSettings"
          :widget-adapter="widgetAdapter"
          :edit-mode-flag="editModeFlag"
        />
      </div>

      <!-- Instrument List Container -->
      <div
        :style="{
          width: showInstrumentList ? '25%' : isHovering ? '9%' : '3.5%',
          transition: 'width 0.3s ease-in-out',
          overflow: 'hidden',
          height: computedHeight + 'px',
        }"
        class="pa-0 no-left-border-vbtn"
        @mouseenter="isHovering = true"
        @mouseleave="isHovering = false"
      >
        <div v-if="!showInstrumentList">
          <!-- Retractable button -->
          <v-btn
            class="vertical-text no-left-border-vbtn"
            :style="{
              height: computedHeight + 'px',
              width: '100%',
              backgroundColor: '#272727',
              color: 'white',
              textAlign: isHovering ? 'center' : 'left',
              transition: 'all 0.3s ease-in-out',
            }"
            outlined
            @click="toggleInstrumentList"
          >
            {{ $t('Analytics.InstrumentMapView.ShowInstrumentList') }}
          </v-btn>
        </div>
        <div
          v-if="showInstrumentList"
          class="pa-0"
        >
          <!-- Retractable button -->
          <v-btn
            class="no-left-border-vbtn"
            width="100%"
            outlined
            style="background-color: #272727; color: white;"
            @click="toggleInstrumentList"
          >
            {{ $t('Analytics.InstrumentMapView.HideInstrumentList') }}
          </v-btn>

          <!-- Retractable column -->
          <v-slide-x-transition>
            <div
              v-if="showInstrumentList"
            >
              <InstrumentTableVueWidget
                :style="{ '--computed-height': computedHeight + 'px' }"
                :widget-settings="instrumentListSettings"
                :widget-adapter="widgetAdapter"
                class="dynamic-height"
              />
            </div>
          </v-slide-x-transition>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import LeafletVueWidget from '@/dashboard/components/widgets/LeafletVueWidget.vue';
import InstrumentTableVueWidget from '@/dashboard/components/widgets/InstrumentTableVueWidget.vue';
import DashboardStackVueWidgetAdapter from '@/dashboard/components/DashboardStackVueWidgetAdapter';

export default {
  components: {
    LeafletVueWidget,
    InstrumentTableVueWidget,
  },
  props: {
    widgetSettings: {
      type: Object,
      default: () => {},
      required: false,
    },
    widgetAdapter: {
      type: DashboardStackVueWidgetAdapter,
      default: () => null,
      required: false,
    },
  },
  data: () => ({
    editModeFlag: false, // controled by the adapter
    form: {
    },
    showInstrumentList: false,
    isHovering: false,
    computedHeight: 36,
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    mapSettings() {
      return this.buildMapSettings();
    },
    instrumentListSettings() {
      return this.buildInstrumentListSettings();
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
    if (this.widgetAdapter?.grid?.el?.clientHeight) {
      const gridItemContentEl = this.widgetAdapter?.grid?.el;
      const gridItemHeight = gridItemContentEl ? gridItemContentEl.clientHeight : 10000;
      this.computedHeight = Math.max(this.$children[0].$el.clientHeight, gridItemHeight);
    } else {
      this.computedHeight = 900;
    }
  },
  methods: {
    toggleInstrumentList() {
      this.showInstrumentList = !this.showInstrumentList;
    },
    buildMapSettings() {
      const query = {
        user: {
          timezone: this.timezone,
          visualisationType: 'leaflet',
        },
        data: {
        },
        visualization: {
        },
      };
      return query;
    },
    buildInstrumentListSettings() {
      const query = {
        user: {
          timezone: this.timezone,
          visualisationType: 'table+instrument',
        },
        data: {
        },
        visualization: {
        },
      };
      return query;
    },
  },
};
</script>

<style lang="scss">
.dynamic-height {
  .grid-stack-item.ui-resizable-autohide.ui-draggable-disabled.ui-resizable-disabled {
    height: var(--computed-height);
    border-radius: 0px;
    border-bottom-right-radius: 8px;
  }
}
.no-left-border-vbtn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-width: 0px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-bottom-width: 0px !important;
  border-top-width: 0px !important;
  .ag-root {
    height: var(--computed-height);
  }
  .ag-unselectable {
    height: var(--computed-height);
  }
  .ag-layout-normal {
    height: var(--computed-height);
  }
  .ag-center-cols-viewport {
    height: var(--computed-height);
  }
  .ag-root-wrapper {
    height: var(--computed-height) !important;
  }
  .ag-theme-balham-dark {
    height: var(--computed-height) !important;
  }
}
.remove-certain-borders {
  .leaflet-container{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  };
  .grid-stack-item.ui-resizable-autohide.ui-draggable-disabled.ui-resizable-disabled {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
}
</style>
