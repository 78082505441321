/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import importal from '@/api/importal';
import { dom } from '@fortawesome/fontawesome-svg-core';
import MapBoxSelect from './leaflet/Map.BoxSelect';
import DashboardStackWidgetBase from './DashboardStackWidgetBase';

const colorway = [
  '#50b0f2', // light blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf', // blue-teal
];

const defaultIconName = 'mdi-map-marker';
const defaultIconSize = 36; // 18, 24, 36, 48
const defaultIconSizeClass = `mdi-${defaultIconSize}px`;
const defaultLayerName = 'Instruments';
const selectedIconColor = 'blue';

const defaultInstrumentIconClasses = colorway.map((x) => (
  window.L.DivIcon.extend({
    options: {
      html: `<i class="mdi ${defaultIconName} ${defaultIconSizeClass}" style="color: ${x};"></i>`,
      iconSize: [defaultIconSize, defaultIconSize],
      iconAnchor: [defaultIconSize / 2, defaultIconSize],
      popupAnchor: [0, -defaultIconSize / 2],
      className: '',
    },
  })
));

const DefaultSelectedInstrumentIconClass = window.L.DivIcon.extend({
  options: {
    html: `<i class="mdi ${defaultIconName} ${defaultIconSizeClass}" style="color: ${selectedIconColor};"></i>`,
    iconSize: [defaultIconSize, defaultIconSize],
    iconAnchor: [defaultIconSize / 2, defaultIconSize],
    popupAnchor: [0, -defaultIconSize / 2],
    className: '',
  },
});

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function iconCreateFunction(cluster) {
  const { L } = window;
  const childCount = cluster.getChildCount();

  let c = ' marker-cluster-';
  if (childCount < 10) {
    c += 'small';
  } else if (childCount < 100) {
    c += 'medium';
  } else {
    c += 'large';
  }

  return new L.DivIcon({ html: `<div><span>${childCount}</span></div>`, className: `marker-cluster${c}`, iconSize: new L.Point(40, 40) });
}

export default class DashboardStackLeafletWidget extends DashboardStackWidgetBase {
  constructor(grid, widget, iface) {
    super(grid, widget, iface);
    this.instrumentIcons = {
      normal: {},
      selected: {},
    };
    this.instrumentIcons.normal['mdi-map-marker'] = defaultInstrumentIconClasses
      .map((X) => new X());
    this.instrumentIcons.selected['mdi-map-marker'] = new DefaultSelectedInstrumentIconClass();
  }

  editModeFlagChanged() {
    super.editModeFlagChanged();
    const editModeFlag = this.interface.editModeFlag();
    if (editModeFlag) {
      this.map.dragging.disable();
    } else {
      this.map.dragging.enable();
    }
  }

  async displayWidget() {
    const { L } = window;

    let settings = null;
    try {
      settings = JSON.parse(this.widget.WidgetSettings);
    } catch (e) {
      return;
    }

    settings.viewport = settings.viewport || {};
    settings.viewport.center = settings.viewport.center || [0, 0];
    settings.viewport.zoom = settings.viewport.zoom || 1;

    settings.tilelayers = settings.tilelayers || [];
    if (settings.tilelayers.length === 0) {
      settings.tilelayers.push({
        layerGroup: 'Streets',
        urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        options: {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          className: 'map-tiles',
        },
      });
    }

    const node = this.createWidgetNode();

    this.mapid = `${this.widget.DashboardsWidgetId}`;
    const div = `<div id="${this.mapid}" style="height: 100%; z-index: 1;"></div>`;

    this.createWidgetContent(div, ''/* additionalMenus */);
    this.map = L.map(this.mapid, {
      attributionControl: false,
      zoomControl: false,
      boxZoom: false,
      boxSelect: true,
    })
      .setView(settings.viewport.center, settings.viewport.zoom)
      .addHandler('boxSelect', MapBoxSelect)
      .on('boxselectend', (e) => this.onBoxSelectEnd(e));

    const baseMaps = {};
    settings.tilelayers.forEach((zz) => {
      const layer = L.tileLayer(zz.urlTemplate, zz.options)
        .addTo(this.map);
      baseMaps[zz.layerGroup] = layer;
    });

    const attributionControl = L.control.attribution({
      position: 'bottomleft',
    }).addTo(this.map);
    attributionControl.setPrefix('');

    L.control.zoom({
      position: 'topright',
    }).addTo(this.map);
    this.widgetClickListeners();
    this.waitForWidgetNode(node);

    const overlayMaps = {};
    this.layerNames = [];

    await importal.get('MapInstrumentList')
      .then((resp) => (resp.data))
      .then((data) => {
        // Build a list of markers that have geopositions
        const points = data
          .filter((x) => !!x.GeoLocation && x.GeoLocation.type === 'Point');

        // List the layer names
        this.layerNames = points
          .map((x) => this.getLayerName(x))
          .filter((x) => onlyUnique);

        const markers = points
          .map((x) => this.makeInstrumentMarker(x));

        // Zoom-in on the markers
        this.defaultBounds = L.featureGroup(markers.map((x) => (x.marker)))
          .getBounds()
          .pad(0.01);
        this.zoomToDefaultBounds();

        const markerClusterGroup = L.markerClusterGroup({
          spiderfyOnMaxZoom: true, // When you click a cluster at the bottom zoom level we spiderfy it so you can see all of its markers.
          showCoverageOnHover: true, // When you mouse over a cluster it shows the bounds of its markers.
          zoomToBoundsOnClick: true, // When you click a cluster we zoom to its bounds.
          removeOutsideVisibleBounds: true, // Clusters and markers too far from the viewport are removed from the map for performance
          spiderLegPolylineOptions: { // Allows you to specify PolylineOptions to style spider legs.
            weight: 1.5,
            color: '#222',
            opacity: 0.5,
          },
          spiderfyDistanceMultiplier: 2.0, // Increase from 1 to increase the distance away from the center that spiderfied markers are placed. Use if you are using big marker icons (Default: 1).
          iconCreateFunction,
        });

        // Add markers into proper layers
        this.layerNames.forEach((layerName) => {
          const layerGroup = L.featureGroup.subGroup(markerClusterGroup, markers
            .filter((x) => x.layer === layerName)
            .map((x) => (x.marker)));
            // .addTo(this.map);
          markerClusterGroup.addLayer(layerGroup);
          overlayMaps[layerName] = layerGroup;
        });

        markerClusterGroup.addTo(this.map);
      });

    this.layerNames.forEach((layerName) => {
      overlayMaps[layerName].addTo(this.map);
    });

    // Add layer switcher control
    L.control.layers(baseMaps, overlayMaps, {
      collapsed: false,
    })
      .addTo(this.map);

    const $this = this;
    const BtnZoomDefaultClass = L.Control.extend({
      options: {
        position: 'bottomright',
      },

      onAdd(map) {
        const container = L.DomUtil.create('div', 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default');
        container.setAttribute('type', 'button');
        container.innerHTML = '<span class="mdi mdi-crop-free mdi-24px"></span>';

        container.onclick = function w() {
          $this.zoomToDefaultBounds();
        };

        return container;
      },
    });

    this.map.addControl(new BtnZoomDefaultClass());

    this.selectedInstrumentsChanged(this.interface.selectedInstruments());
  }

  resizeToContainer(gridstackNode) {
    const rect = {
      w: gridstackNode.el.clientWidth,
      h: gridstackNode.el.clientHeight,
    };
    const el = document.getElementById(this.mapid);
    el.width = rect.w;
    el.height = rect.h;
    this.map.invalidateSize();
  }

  makeInstrumentMarker(x) {
    const { L } = window;

    const layerName = this.getLayerName(x);

    const marker = L.marker(
      x.GeoLocation.coordinates.reverse(),
      {
        icon: this.getLayerIcon(x, 'normal'),
        data: x,
      },
    )
      .bindPopup(`<span class="d-block subtitle-1"><span style="color: #a0a0a0;">Instrument: </span>${x.Name}</span>`
        + `<span class="d-block body-2"><span style="color: #a0a0a0;">Access group: </span>${x.AccessGroupName}</span>`
        + `<span class="d-block body-2"><span style="color: #a0a0a0;">Gateway: </span>${x.GatewayName}</span>`)
      .bindTooltip(`${x.Name}`, {
        permanent: true,
        direction: 'bottom',
        className: 'map-name-tooltip',
      });
    marker.on('click', (e) => this.handleInstrumentSelection(e));
    return {
      layer: layerName,
      marker,
    };
  }

  getLayerIcon(x, iconClass) {
    const { L } = window;

    const layerName = this.getLayerName(x);
    const colorwayIndex = this.layerNames
      .findIndex((y) => y === layerName) % colorway.length;
    const iconName = this.getIconName(x);
    if (this.instrumentIcons[iconClass]
          && this.instrumentIcons[iconClass][iconName]) {
      if (iconClass === 'selected') {
        return this.instrumentIcons[iconClass][iconName];
      }
      return this.instrumentIcons[iconClass][iconName][colorwayIndex];
    }

    // Initialize icon
    this.instrumentIcons.normal[iconName] = colorway.map((y) => (
      L.DivIcon.extend({
        options: {
          html: `<i class="mdi ${iconName} ${defaultIconSizeClass}" style="color: ${y};"></i>`,
          iconSize: [defaultIconSize, defaultIconSize],
          iconAnchor: [defaultIconSize / 2, defaultIconSize],
          popupAnchor: [0, -defaultIconSize / 2],
          className: '',
        },
      })
    )).map((X) => (new X()));
    [this.instrumentIcons.selected[iconName]] = [selectedIconColor].map((y) => (
      L.DivIcon.extend({
        options: {
          html: `<i class="mdi ${iconName} ${defaultIconSizeClass}" style="color: ${y};"></i>`,
          iconSize: [defaultIconSize, defaultIconSize],
          iconAnchor: [defaultIconSize / 2, defaultIconSize],
          popupAnchor: [0, -defaultIconSize / 2],
          className: '',
        },
      })
    )).map((X) => (new X()));

    if (iconClass === 'selected') {
      return this.instrumentIcons[iconClass][iconName];
    }
    return this.instrumentIcons[iconClass][iconName][colorwayIndex];
  }

  getLayerName(x) {
    const layerName = x.Attributes
      .filter((y) => y.Name === '$LAYER$');
    if (layerName.length > 0) {
      return layerName[0].ValueStr;
    }
    return defaultLayerName;
  }

  getIconName(x) {
    const iconName = x.Attributes
      .filter((y) => y.Name === '$ICON$');
    if (iconName.length > 0) {
      return iconName[0].ValueStr;
    }
    return defaultIconName;
  }

  deselectAllMarkers() {
    const { L } = window;
    this.map.eachLayer((l) => {
      if (l && l instanceof L.Marker && l.options.data) {
        l.setIcon(this.getLayerIcon(l.options.data, 'normal'));
      }
    });
  }

  handleInstrumentSelection(event) {
    const { L } = window;

    this.deselectAllMarkers();

    const marker = event.target;
    marker.setIcon(this.getLayerIcon(marker.options.data, 'selected'));
    this.interface.getStore()
      .commit('instrument/select', [marker.options.data]);
  }

  onBoxSelectEnd(event) {
    const { L } = window;

    this.deselectAllMarkers();

    event.containedMarkers.forEach((marker) => {
      marker.setIcon(this.getLayerIcon(marker.options.data, 'selected'));
    });

    this.interface.getStore()
      .commit('instrument/select',
        event.containedMarkers
          .map((x) => x.options.data));
  }

  selectedInstrumentsChanged(event) {
    const { L } = window;

    this.map.eachLayer((l) => {
      if (l && l instanceof L.Marker && l.options.data) {
        if (event.find((x) => x.InstrumentId === l.options.data.InstrumentId)) {
          l.setIcon(this.getLayerIcon(l.options.data, 'selected'));
        } else {
          l.setIcon(this.getLayerIcon(l.options.data, 'normal'));
        }
      }
    });
  }

  zoomToDefaultBounds() {
    this.map.fitBounds(this.defaultBounds);
  }
}
