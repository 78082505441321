import api from '@/api/importal';
import Vue from 'vue';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  filestree: {},
  currentRowData: [],
  path: [],
  isLastPathAFile: false,
  fileContent: '',
  filename: '',
};

const actions = {
  async fetchTree({ commit }, params) {
    await withWait({ commit }, () => api.get(`ModemApiModemGetFileTree/${params.modemMacAddress}`))
      .then((response) => {
        if (response.status === 200) {
          commit('setTree', response.data);
          commit('updateRowData');
        }
      });
  },

  async fetchTreeNoWait({ commit }, params) {
    await api.get(`ModemApiModemGetFileTree/${params.modemMacAddress}`)
      .then((response) => {
        if (response.status === 200) {
          commit('setTree', response.data);
          commit('updateRowData');
        }
      });
  },

  async getFile({ commit, state }) {
    const urlPath = `ModemApiModemReadFileContent/${state.path.join('/')}`;
    const config = { headers: { 'Content-Type': null }, responseType: 'blob' };
    await withWait({ commit }, () => api.get(urlPath, config))
      .then((response) => {
        const reader = new FileReader();
        reader.onload = () => { commit('fileContent', reader.result); };
        reader.readAsText(response.data);
      });
  },

  async sendFile({ commit, state }) {
    const urlPath = `ModemApiModemWriteFileContent/${state.path.join('/')}`;
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, 'axios-retry': { retries: 0 } };
    const file = new Blob([state.fileContent], { type: 'text/plain' });
    const formData = new FormData();
    formData.append('file', file, state.filename);
    await withWait({ commit }, () => api.put(urlPath, formData, config));
  },

  async sendDroppedFile({ commit, state }) {
    const tempPath = [...state.path, state.filename];
    const urlPath = `ModemApiModemWriteFileContent/${tempPath.join('/')}`;
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, 'axios-retry': { retries: 0 } };
    const file = new Blob([state.fileContent], { type: 'text/plain' });
    const formData = new FormData();
    formData.append('file', file, state.filename);
    await withWait({ commit }, () => api.put(urlPath, formData, config))
      .then(() => {
        const datetime = new Date(Date.now());
        commit('addNode', {
          name: state.filename,
          type: 'file',
          saved_time: datetime,
        });
        commit('filename', '');
        commit('fileContent', '');
      });
  },

  async createItem({ commit, state }, params) {
    if (state.currentRowData.find((node) => node.name === params.itemName)) { return; }

    const urlPath = `ModemApiModemCreateItem/${state.path.join('/')}`;
    const data = { name: params.itemName, type: params.itemType };

    await withWait({ commit }, () => api.put(urlPath, data, { 'axios-retry': { retries: 0 } }))
      .then(() => {
        const datetime = new Date(Date.now());
        switch (params.itemType) {
          case 'file':
            commit('addNode', {
              name: params.itemName,
              type: params.itemType,
              saved_time: datetime,
            });
            break;
          case 'folder':
            commit('addNode', {
              name: params.itemName,
              type: params.itemType,
              items: [],
            });
            break;
          default:
        }
        commit('updateRowData');
      });
  },

  async renameItem({ commit, state }, params) {
    const tempPath = [...state.path, params.name].join('/');
    const urlPath = `ModemApiModemRenameItem/${tempPath}`;
    const data = { name: params.newName, type: params.type };
    await api.put(urlPath, data, { 'axios-retry': { retries: 0 } })
      .then(() => {
        commit('renameNode', { newName: params.newName, name: params.name });
      });
  },

  async deleteItem({ commit, state }, params) {
    const urlPath = `ModemApiModemDeleteItem/${state.path.join('/')}`;
    const data = { name: params.name, type: params.type };
    await api.delete(urlPath, { data, 'axios-retry': { retries: 0 } })
      .then(() => {
        commit('deleteNode', params.name);
        commit('updateRowData');
      });
  },
};

const mutations = {
  setTree(state, payload) {
    state.filestree = payload;
    if (state.path.length === 0) { state.path = [payload?.name] || []; }
  },

  deleteNode(state, nodeName) {
    for (let i = 0; i < state.currentRowData.length; i += 1) {
      if (state.currentRowData[i].name === nodeName) {
        state.currentRowData.splice(i, 1);
      }
    }
  },

  addNode(state, newNode) {
    const nodeIndex = state.currentRowData.findIndex((nodeItem) => nodeItem.name === newNode.name);
    if (nodeIndex === -1) {
      state.currentRowData.push(newNode);
    } else {
      Vue.set(state.currentRowData, nodeIndex, newNode);
    }
  },

  renameNode(state, params) {
    const nodeIndex = state.currentRowData.findIndex((nodeItem) => nodeItem.name === params.name);
    if (nodeIndex !== -1) {
      Vue.set(state.currentRowData, nodeIndex, {
        ...state.currentRowData[nodeIndex],
        name: params.newName,
      });
    }
  },

  updatePath(state, newPath) {
    state.path = newPath;
  },

  updateRowData(state) {
    const traverseTree = (tree, pathSegments) => {
      if (pathSegments.length === 0) {
        state.isLastPathAFile = (tree.type === 'file');
        return tree.items || [];
      }
      const [current, ...remainingPath] = pathSegments;
      const nextNode = (tree.items || []).find((item) => item.name === current);
      return nextNode ? traverseTree(nextNode, remainingPath) : [];
    };
    state.currentRowData = traverseTree(state.filestree, state.path.slice(1));
  },
  fileContent(state, content) {
    state.fileContent = content;
  },
  filename(state, content) {
    state.filename = content;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
