<template>
  <v-container
    class="pa-0"
    style="max-width:100%"
  >
    <SubscribeMenu
      v-if="OpenMenu"
      @close-subscribe-menu="OpenMenu = false"
    />
    <!-- Settings panel -->
    <SubscribeSetting
      v-if="OpenSetting"
      :sensor-list="SensorList"
      @close-subscribe="OpenSetting = false"
      @save="handleSaveAlert"
    />
    <ReferenceSetting
      v-if="OpenReference"
      :widgetobj="widgetObj"
      @close-reference="OpenReference = false"
      @save="handleSave"
    />
    <VisualizationSettings
      :edit-mode-flag="editModeFlag && !!activeWidgetId && visualisationSettingsAllowed"
      :dashboard="dashboard"
      @cancel="handleCancel"
      @beforeCreate="handleBeforeCreate"
      @create="handleCreate"
      @save="handleSave"
    />
    <div
      id="grid-container"
      :style="{
        height: `calc(${placeholders.length > 0 ? '90vh' : '100vh'} - 112px)`,
        overflow: 'auto',
        padding: '7px',
      }"
    >
      <div
        id="grid-container-loader"
        class="loader"
        style="display: none;"
      />
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';
import VisualizationSettings from './VisualizationSettings.vue';
import DashboardStackPlotlyWidget from './DashboardStackPlotlyWidget';
import DashboardStackLeafletWidget from './DashboardStackLeafletWidget';
import DashboardStackVueWidgetAdapter from './DashboardStackVueWidgetAdapter';
import InstrumentTableVueWidget from './widgets/InstrumentTableVueWidget.vue';
import DataTableVueWidget from './widgets/DataTableVueWidget.vue';
import DashboardListVueWidget from './widgets/DashboardListVueWidget.vue';
import SubscribeSetting from './subscription/SubscribeSetting.vue';
import SubscribeMenu from './SubscribeMenu.vue';
import CardVueWidgetVue from './widgets/CardVueWidget.vue';
import MediaFeedVueWidget from './widgets/MediaFeedVueWidget.vue';
import InstrumentMapView from '@/analytics/InstrumentMapView.vue';
import ReferenceSetting from './ReferenceLines.vue';
import { onExportExcel, onSensorQueryDebug } from './DashboardWidgetExport';

function VisualisationTypeIs(settings, x) {
  return settings
        && settings.user
        && settings.user.visualisationType
        && settings.user.visualisationType === x;
}

export default {
  components: {
    VisualizationSettings,
    // eslint-disable-next-line vue/no-unused-components
    InstrumentTableVueWidget,
    // eslint-disable-next-line vue/no-unused-components
    DataTableVueWidget,
    // eslint-disable-next-line vue/no-unused-components
    DashboardListVueWidget,
    SubscribeSetting,
    SubscribeMenu,
    ReferenceSetting,
  },
  props: {
    activateApi: {
      type: Boolean,
    },
    dashboard: {
      type: Object,
      default: () => {},
      required: true,
    },
    editModeFlag: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    cellHeight: {
      type: Number,
      default: () => 1,
      required: false,
    },
  },
  data: () => ({
    Grids: [],
    registeredOnClickListeners: [], // CLick listener list for widgets
    resizeEventTriggered: false, // Resize operation
    creatingWidget: null,
    widgetInstances: [],
    OpenSetting: false,
    OpenReference: false,
    OpenMenu: false,
    SensorList: [],
    widgetObj: null,
    RefreshDispatch: {},
    selectedWidget: null,
    showPopup: false,
  }),
  i18n: {
    messages: {
      en: {
        EmptyVisualizationLabel: 'Configure visualization',
        'Export XSLX': 'Export XSLX',
        'Export KQL': 'Export KQL',
        'Delete Widget': 'Delete Widget',
        Subscribe: 'Add subscription',
        'Reference Line': 'Add reference line',
        'Open Subscribe Menu': 'Subscriptions...',
      },
      fr: {
        EmptyVisualizationLabel: 'Configurer la visualisation',
        'Export XSLX': 'Exporter XLSX',
        'Export KQL': 'Exporter KQL',
        'Delete Widget': 'Supprimer le widget',
        Subscribe: 'Ajouter une souscription',
        'Reference Line': 'Ajouter une ligne de référence',
        'Open Subscribe Menu': 'Souscriptions...',
      },
    },
  },
  computed: {
    ...mapState('dashboard', ['dashboards']),
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId', 'visualisationSettingsAllowed']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapState('instrument', ['selectedInstruments']),
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds', 'globalDateRange']),
    ...mapState('dashboardPlaceholders', ['placeholders', 'selectedPlaceholderSensors']),
    ...mapState('devices/instruments/units', ['allUnits']),
  },
  watch: {
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        await this.menuRefreshDashboard();
      }
    },
    async timezone() {
      await this.menuRefreshDashboard();
    },
    selectedInstruments(val) {
      this.widgetInstances.forEach(async (x) => {
        x.instance.selectedInstrumentsChanged(val);
      });
    },
    editModeFlag(val) {
      const toolbarVisible = val ? 'inline-block' : 'none';
      document.getElementsByClassName('widget-toolbar').forEach((d) => {
        // eslint-disable-next-line no-param-reassign
        d.style.display = toolbarVisible;
      });

      const found = this.Grids.find((d) => (d.DashboardId === this.dashboard.dashboardId));
      if (!found) {
        this.menuRefreshDashboard();
      }

      // Freeze / unfreeze gridstack widgets
      this.dashboardWidgets.forEach((widget) => {
        // const found = this.Grids.find((d) => (d.DashboardId === widget.DashboardId));
        if (found) {
          const { grid } = found;
          grid.update(
            document.querySelectorAll(`[gs-id="${widget.DashboardsWidgetId}"]`)[0], {
              noResize: !this.editModeFlag,
              noMove: !this.editModeFlag,
            },
          );
        }
      });

      // Notify widget that edit mode changed
      this.widgetInstances.forEach(async (x) => {
        x.instance.editModeFlagChanged();
      });

      this.updateActiveWidget();
    },
    'dashboard.dashboardId': async function dashboardId(val) {
      this.$store.dispatch('dashboardWidget/resetWidgets');
      await this.refreshDashboard(val);
    },
    async globalDateRange(newValue, oldValue) {
      if (oldValue.activated || newValue.activated) {
        await this.menuRefreshDashboard();
      }
    },
    async placeholders() {
      await this.menuRefreshDashboard();
    },
    async selectedPlaceholderSensors() {
      await this.menuRefreshDashboard();
    },
  },
  async mounted() {
    await this.$store.dispatch('devices/instruments/units/getAllUnits');
    window.addEventListener('resize', this.disableEditMode);
    // Emits on mount
    this.emitInterface();
  },
  beforeDestroy() {
    // Cleanup resources
    this.removeAllListeners();
    this.widgetInstances.forEach(async (x) => {
      await this.unmountWidget(x.widget.DashboardsWidgetId);
    });
  },
  methods: {
    closePopup() {
      this.showPopup = false;
      this.selectedWidget = null;
    },
    async withOverlayAsync(body) {
      this.$store.commit('app/pleaseWait', true);
      await body()
        .finally(() => { this.$store.commit('app/pleaseWait', false); });
    },

    findWidget(dashboardsWidgetId) {
      return this.widgetInstances
        .find((x) => x.widget.DashboardsWidgetId === dashboardsWidgetId);
    },

    async displayWidget(grid, widget) {
      // console.log(widget.DashboardsWidgetId, JSON.parse(widget.WidgetSettings));

      const vue = this;
      const Id = widget.DashboardsWidgetId;
      if (vue.RefreshDispatch[Id] !== null && vue.RefreshDispatch[Id] !== undefined) {
        clearTimeout(vue.RefreshDispatch[Id]);
        vue.RefreshDispatch[Id] = null;
      }
      vue.RefreshDispatch[Id] = setTimeout(async () => {
        vue.updateWidgetSettings(widget);
        await vue.displayWidgetInternal(grid, widget);
        clearTimeout(vue.RefreshDispatch[Id]);
        vue.RefreshDispatch[Id] = null;
      }, 500);
    },

    async displayWidgetInternal(grid, widget) {
      let settings = null;
      try {
        settings = JSON.parse(widget.WidgetSettings);
      } catch (e) {
        return;
      }

      let thisInstance = this.findWidget(widget.DashboardsWidgetId);

      if (!thisInstance) {
        const iface = {
          getStore: () => (this.$store),
          getRouter: () => (this.$router),
          getTimeZone: () => (this.timezone),
          editModeFlag: () => (this.editModeFlag),
          cellHeight: () => (this.cellHeight),
          selectedInstruments: () => (this.selectedInstruments),
          clearResizeEventFlag: () => { this.resizeEventTriggered = false; },
          updateActiveWidget: (widgetId) => { this.updateActiveWidget(widgetId); },
          removeWidget: (widgetId) => { this.removeWidget(grid, widgetId); },
          refreshWidget: (widgetId) => { this.refreshWidget(grid, widgetId); },
          expandWidget: () => { this.returnFromFullScreen(); },
          getVueParent: () => (this), // required by DashboardStackVueWidgetAdapter
          OpenSubscribeSetting: (Sensors) => {
            this.OpenSetting = true;
            this.widgetObj = Sensors;
            this.SensorList = Sensors;
          },
          OpenReferenceSetting: (widgetId) => { this.widgetObj = widgetId; this.OpenReference = true; },
          OpenSubscribeMenu: () => { this.OpenMenu = true; },
          onExportExcel: () => { onExportExcel(widget); },
          onSensorQueryDebug: () => { onSensorQueryDebug(widget); },
        };
        // Create widget instance
        if (VisualisationTypeIs(settings, 'card')) {
          thisInstance = {
            widget,
            grid,
            instance: new DashboardStackVueWidgetAdapter(grid, widget, iface, CardVueWidgetVue),
          };
        } else if (VisualisationTypeIs(settings, 'leaflet')) {
          if (this.legacyMap) {
            thisInstance = {
              widget,
              grid,
              instance: new DashboardStackLeafletWidget(grid, widget, iface),
            };
          } else {
            thisInstance = {
              widget,
              grid,
              instance: new DashboardStackVueWidgetAdapter(grid, widget, iface, InstrumentMapView),
            };
          }
        } else if (VisualisationTypeIs(settings, 'table+instrument')) {
          thisInstance = {
            widget,
            grid,
            instance: new DashboardStackVueWidgetAdapter(grid, widget, iface, InstrumentTableVueWidget),
          };
        } else if (VisualisationTypeIs(settings, 'table+data')) {
          thisInstance = {
            widget,
            grid,
            instance: new DashboardStackVueWidgetAdapter(grid, widget, iface, DataTableVueWidget),
          };
        } else if (VisualisationTypeIs(settings, 'file+feed+picture')) {
          thisInstance = {
            widget,
            grid,
            instance: new DashboardStackVueWidgetAdapter(grid, widget, iface, MediaFeedVueWidget),
          };
        } else if (VisualisationTypeIs(settings, 'list+dashboard')) {
          thisInstance = {
            widget,
            grid,
            instance: new DashboardStackVueWidgetAdapter(grid, widget, iface, DashboardListVueWidget),
          };
        } else {
          thisInstance = {
            widget,
            grid,
            instance: new DashboardStackPlotlyWidget(grid, widget, iface),
          };
        }
        if (thisInstance) {
          this.widgetInstances
            .push(thisInstance);
        }
      } else {
        thisInstance.instance
          .updateWidgetNode(widget);
      }

      if (thisInstance) {
        await thisInstance.instance
          .displayWidget();
      }
    },

    createGrid(dashboardId) {
      const thisGrid = this.Grids.find((d) => (d.DashboardId === dashboardId));
      if (thisGrid) {
        thisGrid.container.style.display = 'block';
        return thisGrid;
      }

      const gridId = `grid-stack-${dashboardId}`;
      const gridEl = document.createElement('div');
      gridEl.classList.add('grid-stack');
      gridEl.id = gridId;
      document.getElementById('grid-container')
        .appendChild(gridEl);

      const grid = {
        DashboardId: dashboardId,
        grid: GridStack.init({
          margin: 0,
          cellHeight: this.cellHeight,
          column: 12,
        }, gridId),
        container: gridEl,
      };

      grid.grid.on('change', (event, items) => {
        if (items && items.length > 0) {
          items.forEach((node) => {
            // Resize widget
            this.widgetInstances.forEach((d) => {
              if (d.widget.DashboardsWidgetId === node.id) {
                d.instance.resizeToContainer(node);
              }
            });

            if (this.editModeFlag) {
              // Only save position update in edit mode
              if (this.creatingWidget && this.creatingWidget.id === node.id) {
                // Resize placeholder
                this.creatingWidget.x = node.x;
                this.creatingWidget.y = node.y;
                this.creatingWidget.w = node.w;
                // Node height is a multiple of cell height, but it is stored in pixels
                this.creatingWidget.h = node.h * this.cellHeight;
              } else if (this.activateApi) {
                // Update widget size
                this.$store.dispatch('dashboardWidget/saveAxis', {
                  DashboardsWidgetId: node.id,
                  Enabled: true,
                  Visible: true,
                  // Node height is a multiple of cell height, but it is stored in pixels
                  AxisHeight: node.h * this.cellHeight,
                  AxisWidth: node.w,
                  AxisY: node.y,
                  AxisX: node.x,
                  AxisLock: false,
                });
              }
            }
          });
        }
      });

      // To prevent click action after resize
      grid.grid.on('resizestart', () => {
        this.resizeEventTriggered = true;
      });

      this.Grids.push(grid);

      // To allow click action after resize
      grid.grid.on('resizestop', () => {
        this.resizeEventTriggered = false;
      });
      return grid;
    },

    async refreshDashboard(dashboardId) {
      this.$store.commit('app/pleaseWait', true);
      document.getElementsByClassName('grid-stack')
        .forEach((d) => {
          // eslint-disable-next-line no-param-reassign
          d.style.display = 'none';
        });
      const { grid } = this.createGrid(dashboardId);
      if (grid.getGridItems().length === 0) {
        document.getElementById('grid-container-loader')
          .style.display = 'block';

        const gridContainerLoader = document.getElementById('grid-container-loader');
        if (gridContainerLoader) {
          gridContainerLoader.style.display = 'none';
        }
      }

      // Query API for widgets to display
      if (this.activateApi) {
        await this.$store.dispatch('dashboardWidget/list', { dashId: dashboardId });
      }
      await this.dashboardWidgets.forEach(async (widget) => {
        await this.displayWidget(grid, widget);
      });
      this.$store.commit('app/pleaseWait', false);
    },

    updateActiveWidget(widgetId = null) {
      if (this.creatingWidget && this.creatingWidget.id !== widgetId) {
        // User clicked on another widget while a widget is in placeholder mode
        const { grid } = this.createGrid(this.dashboard.dashboardId);
        grid.removeWidget(document.querySelectorAll(`[gs-id="${this.creatingWidget.id}"]`)[0]);
        this.creatingWidget = null;
      }

      const activeWidgets = document.querySelectorAll('.active-widget');

      for (let j = 0; j < activeWidgets.length; j += 1) {
        activeWidgets[j].classList.remove('active-widget');
      }

      if (widgetId && this.editModeFlag && !this.resizeEventTriggered) {
        const element = document.querySelectorAll(`[gs-id="${widgetId}"]`)[0];
        this.$store.commit('dashboardWidget/activeWidgetId', widgetId);
        if (element) {
          element.classList.add('active-widget');
        }
      } else {
        this.$store.commit('dashboardWidget/activeWidgetId', null);
      }
    },

    // Create a placeholder for a new widget
    menuAddNewWidget() {
      if (this.dashboard.dashboardId) {
        const { grid } = this.createGrid(this.dashboard.dashboardId);

        this.updateActiveWidget();

        const widgetId = crypto.randomUUID();
        const gsw = grid.addWidget({
          w: 4,
          // Node height is stored in pixels but must be converted to multiple of cell height
          h: Math.ceil(400 / this.cellHeight),
          id: widgetId,
          content: this.$i18n.t('EmptyVisualizationLabel'),
        });

        this.creatingWidget = {
          id: widgetId,
          x: Number(gsw.getAttribute('gs-x')),
          y: Number(gsw.getAttribute('gs-y')),
          w: Number(gsw.getAttribute('gs-w')),
          h: Number(gsw.getAttribute('gs-h')) * this.cellHeight,
        };

        this.updateActiveWidget(widgetId);
      }
    },

    // This refreshes a widget
    async refreshWidget(grid, widgetId) {
      const thisInstance = this.findWidget(widgetId);
      await this.displayWidget(grid, thisInstance.widget);
    },

    async returnFromFullScreen() {
      this.$emit('refreshDash');
    },

    // This deletes a widget from dashboard permamently
    async removeWidget(grid, widgetId) {
      grid.removeWidget(document.querySelectorAll(`[gs-id="${widgetId}"]`)[0]);
      if (this.activateApi) { await this.$store.dispatch('dashboardWidget/remove', { WidgetId: widgetId }); }
      this.updateActiveWidget();
      this.unmountWidget(widgetId);
    },

    // Just remove the widget from the screen
    unmountWidget(widgetId) {
      const thisInstance = this.widgetInstances
        .find((x) => x.widget.DashboardsWidgetId === widgetId);
      if (thisInstance) {
        // Unmount and remove instance
        thisInstance.instance.unmountWidget();
        this.widgetInstances = this.widgetInstances
          .filter((x) => x !== thisInstance);
      }
    },

    // #region Handle settings pannel events
    async handleCancel(isNew) {
      if (isNew) {
        const { grid } = this.createGrid(this.dashboard.dashboardId);
        grid.removeWidget(document.querySelectorAll(`[gs-id="${this.activeWidgetId}"]`)[0]);
        this.creatingWidget = null;
      }
      this.updateActiveWidget();
    },
    async handleBeforeCreate() {
      // const { grid } = this.createGrid(this.dashboard.dashboardId);
      // grid.removeWidget(document.querySelectorAll(`[gs-id="${this.activeWidgetId}"]`)[0]);
    },
    async handleCreate(createOpts) {
      await this.withOverlayAsync(async () => {
        const payload = {
          DashboardId: createOpts.DashboardId,
          DashboardsWidgetId: createOpts.DashboardsWidgetId,
          DatasourceSettings: createOpts.DatasourceSettings,
          WidgetSettings: createOpts.WidgetSettings,
          WidgetId: createOpts.WidgetId,
          Enabled: createOpts.Enabled,
          Visible: createOpts.Visible,
          AxisX: this.creatingWidget.x,
          AxisY: this.creatingWidget.y,
          AxisWidth: this.creatingWidget.w,
          AxisHeight: this.creatingWidget.h,
          AxisLock: createOpts.AxisLock,
        };
        if (this.activateApi) {
          await this.$store.dispatch('dashboardWidget/create', payload);
        } else {
          // Create in local store
          await this.$store.commit('dashboardWidget/createWidget', payload);
        }
        this.creatingWidget = null; // Widget is created
        if (this.activateApi) { await this.$store.dispatch('dashboardWidget/list', { dashId: this.dashboard.dashboardId }); }

        const { grid } = this.createGrid(this.dashboard.dashboardId);
        await this.displayWidget(grid, this.activeWidget, true);
        this.updateActiveWidget();
      });
    },
    async handleSave(event) {
      await this.withOverlayAsync(async () => {
        if (this.activateApi) {
          // Save to database
          await this.$store.dispatch('dashboardWidget/saveWidgetSettings', event);
          await this.$store.dispatch('dashboardWidget/list', { dashId: this.dashboard.dashboardId });
        } else {
          // Save in local store
          this.$store.commit('dashboardWidget/saveWidgetSettings', event);
        }
        const { grid } = this.createGrid(this.dashboard.dashboardId);
        if (this.activeWidget) {
          await this.displayWidget(grid, this.activeWidget, true);
          // SAVE THE CHANGE TO THE LOCAL CHARGED INSTANCE
          const thisInstance = this.findWidget(this.activeWidget.DashboardsWidgetId);
          if (thisInstance) {
            thisInstance.widget.WidgetSettings = this.activeWidget.WidgetSettings;
          }
        } else if (this.widgetObj) {
          await this.displayWidget(grid, this.widgetObj, true);
        } else {
          await this.displayWidget(grid, this.activeWidget, true);
        }
        this.updateActiveWidget();
      });
    },

    async handleSaveAlert() {
      await this.withOverlayAsync(async () => {
        const { grid } = this.createGrid(this.dashboard.dashboardId);
        const widSet = JSON.parse(this.widgetObj.WidgetSettings);
        const currentDate = new Date();
        const newTime = {
          ts: currentDate.getTime(),
        };
        widSet.visualization.ts = newTime;
        this.widgetObj.WidgetSettings = JSON.stringify(widSet);
        await this.displayWidget(grid, this.widgetObj, true);
        this.updateActiveWidget();
      });
    },
    // #endregion

    emitInterface() {
      this.$emit('interface', {
        menuAddNewWidget: () => this.menuAddNewWidget(),
        menuRefreshDashboard: () => this.menuRefreshDashboard(),
      });
    },

    async menuRefreshDashboard() {
      const { grid } = this.createGrid(this.dashboard.dashboardId);
      await this.dashboardWidgets.forEach(async (widget) => {
        await this.displayWidget(grid, widget);
      });
    },

    updateWidgetSettings(widget) {
      const settings = JSON.parse(widget.WidgetSettings);

      this.$store.dispatch('dashboardWidget/updateDateRangeAndAggregation', {
        widgetSettings: settings,
        visualisationSubType: settings.user.visualisationSubType,
      });

      if (settings.user.visualisationType === 'card') {
        this.$store.dispatch('visualisationSettingsCard/jsonWidgetSettings', {
          widgetSettings: settings,
          visualisationSubType: settings.user.visualisationSubType,
        });
      } else if (settings.user.visualisationType === 'plotly') {
        this.$store.dispatch('visualisationSettingsPlotlyGraph/jsonWidgetSettings', {
          widgetSettings: settings,
          visualisationSubType: settings.user.visualisationSubType,
          units: this.allUnits,
        });
      } else if (settings.user.visualisationType === 'crossplotly') {
        this.$store.dispatch('visualisationSettingsCrossPlotlyGraph/jsonWidgetSettings', {
          widgetSettings: settings,
          visualisationSubType: settings.user.visualisationSubType,
          units: this.allUnits,
        });
      }

      const item = widget;
      item.WidgetSettings = JSON.stringify(settings);
      this.$store.commit('dashboardWidget/saveWidgetSettings', item);
    },

    removeAllListeners() {
      this.registeredOnClickListeners.forEach((listen) => {
        listen.widget.removeEventListener(listen.event, listen.func);
      });
    },

    disableEditMode() {
      if (this.editModeFlag) {
        this.$root.$emit('toggle-edit-mode');
      }
    },
  },
};
</script>

<style>
</style>
