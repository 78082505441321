<template>
  <v-menu
    v-model="isMenuOpen"
    transition="scale-transition"
    offset-x
    right
    scroll-strategy="close"
  >
    <template #activator="{ on }">
      <button
        class="v-btn text text-style"
        style="text-transform: none !important;"
        v-on="on"
        @click.stop
      >
        <v-icon
          :class="['mdi', 'x-small', icon]"
          style="margin-right: 10px; color: inherit;"
        />
        <span class="text-style">{{ text }}</span>
      </button>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :value="index"
        @click="item.action"
      >
        <v-list-item-title>{{ item.title || 'Unnamed' }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FileButtonMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
      default: 'Undefined',
    },
    icon: {
      type: String,
      default: 'mdi-file',
    },
  },
  data() {
    return {
      isMenuOpen: false,
      gridContainer: null,
    };
  },
  mounted() {
    this.gridContainer = document.querySelector('.ag-body-viewport');
    if (this.gridContainer) {
      this.gridContainer.addEventListener('scroll', this.closeMenuOnScroll);
    }
  },
  beforeDestroy() {
    // Clean up event listener
    if (this.gridContainer) {
      this.gridContainer.removeEventListener('scroll', this.closeMenuOnScroll);
    }
  },
  methods: {
    closeMenuOnScroll() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false; // Close the menu on scroll
      }
    },
  },
};
</script>

<style scoped>

.text-style {
  font-size: inherit !important;
  font-weight: inherit !important;
  text-transform: none;
  color: inherit !important;
}
</style>
