<template>
  <v-dialog
    v-model="show"
    max-width="600"
  >
    <v-card class="px-5">
      <v-card-title class="justify-center">
        {{ title }}
      </v-card-title>
      <template v-if="hasMapFields">
        <v-text-field
          v-model="model.Name"
          :label="$t('Analytics.MapManagement.Name')"
          class="mt-4"
          clearable
          dense
        />
        <v-text-field
          v-model="model.Group"
          :label="$t('Analytics.MapManagement.Group')"
          class="mt-4"
          clearable
          dense
        />
        <v-select
          v-model="model.AccessGroupId"
          :items="accessgroups"
          :label="$t('Analytics.MapManagement.AccessGroup')"
          item-text="AccessGroupName"
          item-value="AccessGroupId"
          class="cursor-pointer"
          hide-details
        />
      </template>
      <v-file-input
        v-if="hasImage"
        v-model="file"
        :label="model.File ? $t('Analytics.MapManagement.Image') : $t('Analytics.MapManagement.ChooseImage')"
        prepend-icon="mdi-image"
        type="file"
        :accept="acceptedFileTypes.join(', ')"
        class="mt-4"
        @change="processImage"
      />
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="show=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          text
          :disabled="saveDisabled"
          @click.stop="$emit('update', model)"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'MapDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    map: {
      type: Object,
      default: () => {},
    },
    isCreate: {
      type: Boolean,
      default: true,
    },
    hasImage: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    acceptedFileTypes: ['image/jpeg', 'image/png'],
    file: null,
    model: {
      Name: null,
      Group: null,
      AccessGroupId: null,
      File: null,
      ImageAspect: null,
    },
  }),
  computed: {
    ...mapState('app', ['tenantId']),
    ...mapGetters('user', ['identity']),
    ...mapGetters('app', ['isTenantNetworkOperator']),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    accessgroups() {
      return this.isTenantNetworkOperator
        ? this.identity.listaccessgroupinfo
        : this.identity.listaccessgroupinfo.filter((x) => x.TenantId === this.tenantId);
    },
    hasMapFields() {
      return !(!this.isCreate && this.hasImage);
    },
    title() {
      let title = `${this.isCreate ? this.$t('Create') : this.$t('Edit')} `;
      title += this.$t(`Analytics.MapManagement.${this.hasMapFields ? 'Map' : 'Image'}`);
      return title;
    },
    saveDisabled() {
      return (this.hasMapFields && (!this.model.Name || !this.model.Group || !this.model.AccessGroupId))
        || (this.hasImage && !this.file);
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.map) {
        this.model = this.map;
      } else {
        this.model = {
          Name: null,
          Group: null,
          AccessGroupId: null,
          File: null,
          ImageAspect: null,
        };
      }

      this.file = null;
    },
    processImage(file) {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        this.model.File = formData;

        const { model } = this;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          const img = new Image();
          // eslint-disable-next-line func-names
          img.onload = function () {
            model.ImageAspect = (this.width / this.height).toFixed(2);
          };

          img.src = reader.result;
        };
      } else {
        this.model.File = null;
        this.model.ImageAspect = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-select ::v-deep .v-select__selections  input {
  display: none;
}

.v-file-input ::v-deep .v-file-input__text {
  cursor: pointer;
}
</style>
