<template>
  <div style="position: relative; height: 100%; z-index: 10;">
    <!-- Instrument Location Selector -->
    <instrument-location-selector
      v-if="instrumentLocationDialog.show"
      v-models:show="instrumentLocationDialog.show"
      v-models:lat="instrumentLocationDialog.latitude"
      v-models:lon="instrumentLocationDialog.longitude"
      @click:submit="instrumentLocationDialog.onSubmit"
    />
    <!-- Map -->
    <div
      ref="map"
      style="height: 100%; z-index: 1;"
    />
    <!-- Buttons Container -->
    <div
      style="
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 3;
        display: flex;
        gap: 10px;
      "
    >
      <v-select
        v-model="mapViewSelector"
        :items="mapViews"
        item-text="Name"
        hide-details
        return-object
        style="width: 250px;"
      />
      <toolbar-button-with-tooltip
        v-if="editModeFlag"
        :tooltip-text="$t('Dashboard.LeafletWidget.PlaceInstrument')"
        icon-name="mdi-map-marker-plus"
        :color="isLocationMode ? 'green' : undefined"
        @click="instrumentSetLocation"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { mapState } from 'vuex';
import api from '@/api/importal';
import MapBoxSelect from '../leaflet/Map.BoxSelect';
import DashboardStackVueWidgetAdapter from '../DashboardStackVueWidgetAdapter';
import InstrumentLocationSelector from '@/dashboard/components/leaflet/InstrumentLocationSelector.vue';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import datehandling from '@/components/datehandling';
import textHandling from '@/components/helpers/textHandling';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

const colorMissingData = '#C0C0C0';

const colorway = [
  '#50b0f2', // light blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf', // blue-teal
];

const defaultIconName = 'mdi-map-marker';
const defaultLayerName = 'Instruments';
const selectedIconColor = 'blue';
let defaultIconSize = 12; // 12, 24, 36, 48

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function iconCreateFunction(cluster) {
  const { L } = window;
  const childCount = cluster.getChildCount();

  let c = ' marker-cluster-';
  if (childCount < 10) {
    c += 'small';
  } else if (childCount < 100) {
    c += 'medium';
  } else {
    c += 'large';
  }

  return new L.DivIcon({ html: `<div><span>${childCount}</span></div>`, className: `marker-cluster${c}`, iconSize: new L.Point(40, 40) });
}

function getLayerName(x) {
  const layerName = x.Attributes
    .filter((y) => y.Name === '$LAYER$');
  if (layerName.length > 0) {
    return layerName[0].ValueStr;
  }
  return defaultLayerName;
}

function getIconName(x) {
  const iconName = x.Attributes
    .filter((y) => y.Name === '$ICON$');
  if (iconName.length > 0) {
    return iconName[0].ValueStr;
  }
  return defaultIconName;
}

function getLayerIcon(x, iconClass, layerNames) {
  const { L } = window;

  const layerName = getLayerName(x);
  const colorwayIndex = layerNames
    .findIndex((y) => y === layerName) % colorway.length;
  const iconName = getIconName(x);

  // Initialize icon
  let borderStyle = '';
  if (x.AlertState?.IsAlertState) {
    borderStyle = `border: 3px solid ${x.AlertState.IsMissingData ? colorMissingData : x.AlertState.AlertColor}; border-radius: ${defaultIconSize}px;`;
  }

  if (iconClass !== 'selected') {
    return colorway.map((y) => (
      L.DivIcon.extend({
        options: {
          html: `<i class="mdi ${iconName}" style="font-size: ${defaultIconSize}px; color: ${y}; ${borderStyle}"></i>`,
          iconSize: [defaultIconSize, defaultIconSize],
          iconAnchor: [defaultIconSize / 2, defaultIconSize],
          popupAnchor: [0, -defaultIconSize / 2],
          className: '',
        },
      })
    )).map((X) => (new X()))[colorwayIndex];
  }

  return [selectedIconColor].map((y) => (
    L.DivIcon.extend({
      options: {
        html: `<i class="mdi ${iconName} pr-1" style="font-size: ${defaultIconSize}px; color: ${y}; ${borderStyle}"></i>`,
        iconSize: [defaultIconSize, defaultIconSize],
        iconAnchor: [defaultIconSize / 2, defaultIconSize],
        popupAnchor: [0, -defaultIconSize / 2],
        className: '',
      },
    })
  )).map((X) => (new X()))[0];
}

function makeInstrumentMarker(x, layerNames, viewId, options) {
  const { L } = window;
  const opt = options || {
    theme: 'dark',
  };

  const layerName = getLayerName(x);

  let coords = null;
  const viewAttr = x.Attributes.find((y) => String(y.Name).startsWith('$VIEW') && y.ValueStr === viewId);
  if (viewAttr !== undefined) {
    const attrIndex = String(viewAttr.Name).substring(5, 7);
    const easting = x.Attributes.find((y) => y.Name === `$EASTING${attrIndex}$`);
    const northing = x.Attributes.find((y) => y.Name === `$NORTHING${attrIndex}$`);
    if (easting !== undefined && northing !== undefined) {
      coords = [northing.ValueInt, easting.ValueInt];
    }
  } else {
    coords = x.GeoLocation.coordinates.reverse();
  }

  const marker = L.marker(
    coords,
    {
      icon: getLayerIcon(x, 'normal', layerNames),
      data: x,
    },
  )
    .bindPopup(`<span class="d-block subtitle-1">${x.Name}</span>`)
    // + `${spanRows}`)
    .bindTooltip(`${x.Name}`, {
      permanent: true,
      direction: 'bottom',
      className: `map-name-tooltip map-name-tooltip-${opt.theme}`,
    });
  return {
    layer: layerName,
    marker,
  };
}

export default {
  components: {
    InstrumentLocationSelector,
    ToolbarButtonWithTooltip,
  },
  props: {
    widgetSettings: {
      type: Object,
      default: () => {},
      required: true,
    },
    widgetAdapter: {
      type: DashboardStackVueWidgetAdapter,
      default: () => null,
      required: false,
    },
    editModeFlag: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      widgetSize: null, // Directly mutated by the adapter
      defaultIconSize: 36,
      map: null,
      layerNames: [],

      baseMapViews: [
        {
          Name: 'World',
          CRS: 'EPSG3857',
          TileLayers: [
            {
              layerGroup: 'ESRI World Imagery',
              urlTemplate: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              options: {
                attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
              },
            },
            {
              layerGroup: 'Open Street Map',
              urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
              options: {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                className: 'map-tiles',
              },
            },
          ],
          bounds: {
            northEast: { lat: 90, lng: 180 },
            southWest: { lat: -90, lng: -180 },
          },
        },
      ],
      mapViewSelector: null,
      selectedMap: null,
      selectedBaseLayerName: null,
      selectedBaseLayer: null,
      defaultLayerName: null,
      defaultMapViewSelector: null,

      layerControl: null,
      markerClusterGroup: null,

      updateMapView: null,
      overlayMaps: null,
      baseMaps: null,

      menuOpen: false, // To control menu visibility

      isLocationMode: false,
      instrumentLocationDialog: {
        show: false,
        latitude: 0.0,
        longitude: 0.0,
        instrument: null,
        onSubmit: (value) => { this.onAddInstrumentSubmit(value); },
      },
    };
  },
  computed: {
    ...mapState('map', ['baseLayers', 'selectedMapView', 'instruments']),
    ...mapState('dashboardWidget', ['dashboardWidgets', 'visualisationSettingsAllowed']),
    mapViews() {
      const rc = this.baseMapViews.concat(this.baseLayers); // Create all views
      return rc;
    },
    layerOptions() {
      // Check if defaultMapViewSelector is selected
      if (this.defaultMapViewSelector) {
        const selectedMapView = this.mapViews.find(
          (view) => view.Name === this.defaultMapViewSelector.Name,
        );
        if (selectedMapView) {
          if (selectedMapView.TileLayers.length > 0) {
            return selectedMapView.TileLayers;
          }
          return selectedMapView.ImageOverlays;
        }
      }
      return [];
    },
    disableSave() {
      return this.defaultMapViewSelector === null || this.defaultLayerName === null;
    },
    isWorldMap() {
      return this.selectedMapView?.Name === this.baseMapViews[0].Name;
    },
  },
  watch: {
    mapViews(rc) {
      this.$nextTick(async () => {
        if (this.widgetSettings.user?.mapOptions?.defaultMap) {
          const index = rc.findIndex(
            (x) => x.Name === this.widgetSettings.user.mapOptions.defaultMap,
          );
          if (index !== undefined) {
            this.$store.dispatch('map/selectMapView', rc[index]); // Select the default map
            // Select default layer
            if (this.widgetSettings.user.mapOptions.defaultLayer) {
              this.selectedBaseLayerName = this.widgetSettings.user.mapOptions.defaultMap;
              this.defaultMapViewSelector = rc.find(
                (x) => x.Name === this.widgetSettings.user.mapOptions.defaultMap,
              );

              if (this.defaultMapViewSelector.ImageOverlays) {
                this.defaultLayerName = this.defaultMapViewSelector.ImageOverlays.find(
                  (x) => x.Layer === this.widgetSettings.user.mapOptions.defaultLayer,
                );
              } else {
                this.defaultLayerName = this.defaultMapViewSelector.TileLayers.find(
                  (x) => x.layerGroup === this.widgetSettings.user.mapOptions.defaultLayer,
                );
              }
            }
          } else {
            this.$store.dispatch('map/selectMapView', rc[0]); // Select the World map
          }
        } else {
          this.$store.dispatch('map/selectMapView', rc[0]); // Select the World map
        }
      });
    },
    mapViewSelector(value) {
      this.$store.dispatch('map/selectMapView', value);
    },
    editModeFlag(value) {
      if (this.map != null) {
        if (value) {
          this.map.dragging.disable();
        } else {
          this.map.dragging.enable();
          this.cancelLocationMode();
        }
      }
    },
    instruments(value) {
      this.updateMapView();
    },
    selectedMapView(value) {
      this.mapViewSelector = value;
      this.doUpdateMapView();
      this.selectedBaseLayer = this.getSelectedBaseLayerName(this.baseMaps);
    },
  },
  async created() {
    this.updateMapView = debounce(this.doUpdateMapView);
    await Promise.all([
      this.$store.dispatch('map/listBaseLayers'),
      this.$store.dispatch('map/listInstruments'),
    ]);
  },
  beforeDestroy() {
    this.map.off('click', this.onMapClick);
  },
  methods: {
    doUpdateMapView() {
      if (this.selectedMapView
        && this.selectedMapView.Name && this.selectedMapView.Name !== this.selectedMap) {
        this.init(this.$refs.map, this.selectedMapView);
        this.selectedMap = this.selectedMapView.Name;
      }
    },

    recomputeBounds(bounds, configData) {
      const config = JSON.parse(configData);
      if (config.aspect !== undefined) {
        const [[x1, y1], [x2, y2]] = bounds;
        return [[x1, y1 * config.aspect], [x2, y2 * config.aspect]];
      }

      return bounds;
    },

    async init(mapcontainer, selectedMapView) {
      const { L } = window;
      const settings = this.widgetSettings;

      if (this.map !== null) {
        this.map.off();
        this.map.remove();
        this.map = null;
      }

      const tilelayers = selectedMapView.TileLayers || [];
      const imageOverlays = selectedMapView.ImageOverlays || [];
      const CRS = this.getCRS(selectedMapView.CRS);

      settings.viewport = settings.viewport || {};
      settings.viewport.center = settings.viewport.center || [0, 0];
      settings.viewport.zoom = settings.viewport.zoom || 1;

      this.map = L.map(mapcontainer, {
        attributionControl: false,
        zoomControl: false,
        boxZoom: false,
        boxSelect: true,
        crs: CRS,
        minZoom: -1,
        maxZoom: 18,
      })
        .setView(settings.viewport.center, settings.viewport.zoom)
        .addHandler('boxSelect', MapBoxSelect)
        .on('boxselectend', (e) => this.onBoxSelectEnd(e));

      const Position = L.Control.extend({
        _container: null,
        options: {
          position: 'bottomleft',
        },

        onAdd(map) {
          const latlng = L.DomUtil.create('div', 'mouseposition');
          // eslint-disable-next-line no-underscore-dangle
          this._latlng = latlng;
          return latlng;
        },

        updateHTML(lat, lng) {
          const latlng = `${lat} ${lng}`;
          // eslint-disable-next-line no-underscore-dangle
          this._latlng.innerHTML = `<span style="color: #3B4955;">Position: ${latlng}</span>`;
        },
      });
      this.position = new Position();
      this.map.addControl(this.position);

      this.map.addEventListener('mousemove', (event) => {
        const lat = Math.round(event.latlng.lat * 100000) / 100000;
        const lng = Math.round(event.latlng.lng * 100000) / 100000;
        this.position.updateHTML(lat, lng);
      });

      const baseMaps = {};
      tilelayers.forEach((zz) => {
        const layer = L.tileLayer(zz.urlTemplate, zz.options)
          .addTo(this.map);
        baseMaps[zz.layerGroup] = layer;
      });

      imageOverlays.forEach((zz) => {
        const layer = L.imageOverlay(zz.url, zz.bounds, zz.options)
          .addTo(this.map);
        baseMaps[zz.layerGroup] = layer;
      });

      const attributionControl = L.control.attribution({
        position: 'bottomleft',
      }).addTo(this.map);
      attributionControl.setPrefix('');

      L.control.zoom({
        position: 'topright',
      }).addTo(this.map);

      const zoomControlElement = document.querySelector('.leaflet-control-zoom');
      if (zoomControlElement) {
        zoomControlElement.style.top = '25px'; // Adjust top spacing so it does not superpose
        // with the refresh and expand buttons
      }

      this.layerNames = [];

      if (this.instruments.length > 0) {
        this.processInstrumentData(this.instruments, baseMaps);
      }

      const $this = this;
      const BtnZoomDefaultClass = L.Control.extend({
        options: {
          position: 'bottomright',
        },

        onAdd() {
          const container = L.DomUtil.create('div', 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default');
          container.setAttribute('type', 'button');
          container.innerHTML = '<span class="mdi mdi-crop-free mdi-24px"></span>';

          container.onclick = function w() {
            $this.zoomToDefaultBounds();
          };

          return container;
        },
      });

      this.map.addControl(new BtnZoomDefaultClass());
    },

    getCRS(name) {
      const { L } = window;
      if (name === 'Simple') {
        return L.CRS.Simple;
      }

      return L.CRS.EPSG3857;
    },

    async processInstrumentData(data, baseMaps) {
      const { L } = window;
      const overlayMaps = {};

      if (baseMaps !== null && baseMaps !== undefined) {
        if (this.layerControl !== null) {
          this.layerControl.remove();
        }
        // Add layer switcher control
        this.layerControl = L.control.layers(baseMaps, overlayMaps, {
          collapsed: false,
          sortLayers: true,
        }).addTo(this.map);
      }

      if (baseMaps !== null && baseMaps !== undefined) {
        if (!(this.selectedBaseLayerName && this.selectedBaseLayer)) {
          // eslint-disable-next-line max-len
          [this.selectedBaseLayerName, this.selectedBaseLayer] = this.getSelectedBaseLayerName(baseMaps) || [null, null];
        }
      }

      const viewId = `${this.selectedMapView.Name}.${this.selectedBaseLayerName}`;

      // Build a list of markers that have geopositions
      const points = data
        .filter((x) => !!x.GeoLocation && x.GeoLocation.type === 'Point')
        .filter((x) => this.selectedMapView.Name === 'World')
        .map((x) => this.$clone(x)) // Clone because leaflet modifies the object
        .concat(data
          .filter((x) => (x.Attributes.find((y) => String(y.Name).startsWith('$VIEW')
              && y.ValueStr === viewId)))
          .map((x) => this.$clone(x)));

      if (this.markerClusterGroup !== null) {
        this.markerClusterGroup.clearLayers();
        this.markerClusterGroup.remove();
        this.markerClusterGroup = null;
      }

      if (this.map.options.crs === L.CRS.Simple) {
        // Zoom-in on the markers
        this.defaultBounds = [[-500, -500], [500, 500]];
        this.zoomToDefaultBounds();
      }

      if (this.widgetSettings.user
        && this.widgetSettings.user.mapOptions
        && this.widgetSettings.user.mapOptions.cluster) {
        defaultIconSize = this.widgetSettings.user.mapOptions.iconsize;
      }

      if (points.length > 0) {
        // List the layer names
        this.layerNames = points
          .map((x) => getLayerName(x))
          .filter((x) => onlyUnique);

        const markers = points
          // eslint-disable-next-line max-len
          .map((x) => makeInstrumentMarker(x, this.layerNames, viewId, this.selectedMapView.Options));

        markers.map((x) => x.marker.on('click', (e) => this.handleInstrumentSelection(e)));

        if (this.map.options.crs !== L.CRS.Simple) {
          // Zoom-in on the markers
          this.defaultBounds = L.featureGroup(markers.map((x) => (x.marker)))
            .getBounds()
            .pad(0.01);
          this.zoomToDefaultBounds();
        }

        let maxClusterRadius = 80;
        if (this.widgetSettings.user
          && this.widgetSettings.user.mapOptions
          && this.widgetSettings.user.mapOptions.cluster) {
          maxClusterRadius = this.widgetSettings.user.mapOptions.cluster;
        }

        const markerClusterGroup = L.markerClusterGroup({
          spiderfyOnMaxZoom: true, // When you click a cluster at the bottom zoom level we spiderfy it so you can see all of its markers.
          maxClusterRadius,
          showCoverageOnHover: true, // When you mouse over a cluster it shows the bounds of its markers.
          zoomToBoundsOnClick: true, // When you click a cluster we zoom to its bounds.
          removeOutsideVisibleBounds: true, // Clusters and markers too far from the viewport are removed from the map for performance
          spiderLegPolylineOptions: { // Allows you to specify PolylineOptions to style spider legs.
            weight: 0.5,
            color: '#222',
            opacity: 0.5,
          },
          spiderfyDistanceMultiplier: 2.0, // Increase from 1 to increase the distance away from the center that spiderfied markers are placed. Use if you are using big marker icons (Default: 1).
          iconCreateFunction,
        });

        // Add markers into proper layers
        this.layerNames.forEach((layerName) => {
          const layerGroup = L.featureGroup.subGroup(markerClusterGroup, markers
            .filter((x) => x.layer === layerName)
            .map((x) => (x.marker)));
          // .addTo(this.map);
          markerClusterGroup.addLayer(layerGroup);
          overlayMaps[layerName] = layerGroup;
        });

        markerClusterGroup.addTo(this.map);
        this.markerClusterGroup = markerClusterGroup;

        this.layerNames.forEach((layerName) => {
          overlayMaps[layerName].addTo(this.map);
        });
      }

      if (baseMaps !== null && baseMaps !== undefined) {
        if (this.layerControl !== null) {
          this.layerControl.remove();
        }
        // Add layer switcher control
        this.layerControl = L.control.layers(baseMaps, overlayMaps, {
          collapsed: false,
          sortLayers: true,
        }).addTo(this.map);

        this.overlayMaps = overlayMaps;
        this.baseMaps = baseMaps;
      } else if (this.overlayMaps[0]) {
        Object.entries(this.overlayMaps).forEach((x) => this.layerControl.removeLayer(x[1]));
        Object.entries(overlayMaps).forEach((x) => this.layerControl.addOverlay(x[1], x[0]));
        this.overlayMaps = overlayMaps;
      }

      document.getElementsByClassName('leaflet-control-layers-selector')
        .forEach((x) => x.addEventListener('click', (e) => { this.onBaselayerchange(e.target.layerId, this.baseMaps); }));
    },

    deselectAllMarkers() {
      const { L } = window;
      this.map.eachLayer((l) => {
        if (l && l instanceof L.Marker && l.options.data) {
          l.setIcon(getLayerIcon(l.options.data, 'normal', this.layerNames));
        }
      });
    },

    async handleInstrumentSelection(event) {
      this.deselectAllMarkers();

      const marker = event.target;

      // use regex to capture only the concerning part
      // eslint-disable-next-line
      let popupContent = marker._popup._content;
      const regexPattern = /((?:[^>]*>){2})/;
      const match = popupContent.match(regexPattern);
      popupContent = match ? match[1] : popupContent;
      marker.setPopupContent(popupContent);

      let dataSensor = await (await api.get(
        `SensorListForInstrument?instrumentId=${marker.options.data.InstrumentId}`
        + `&vo=0&so=0&sc=${100}&vc=${0}`,
      )).data;

      const sensorIds = dataSensor.map((x) => x.Id);
      let sensorAlertStates = [];
      await api.get(`SensorAlertState?sensorIds=${sensorIds}`).then(async (response) => {
        sensorAlertStates = await response.data;

        marker.options.data.AlertState = {
          IsAlertState: sensorAlertStates.some((x) => x.IsAlertState),
          AlertColor: sensorAlertStates.length === 1 ? sensorAlertStates[0].AlertColor : '#FF0000',
          IsMissingData: sensorAlertStates.length ? sensorAlertStates.every((x) => x.IsMissingData) : false,
        };

        marker.setIcon(getLayerIcon(marker.options.data, 'selected', this.layerNames));
        this.$store.commit('instrument/select', [marker.options.data]);
      });

      let spanRows = dataSensor.length ? '<table>' : '';
      let timestamp = null;

      const formulas = [];
      // eslint-disable-next-line camelcase
      const sensor_selection = [];
      // eslint-disable-next-line camelcase
      const input_filter = {
        last_n: {
          scale: 'YEAR',
          period: 15,
        },
      };
      // eslint-disable-next-line camelcase
      const input_time_axis = {
        round_to: {
          scale: 'MINUTE',
          multiplier: 1,
          divisor: 1,
        },
      };
      // eslint-disable-next-line camelcase
      const output_time_axis = {
        round_to: {
          scale: 'NONE',
          multiplier: 1,
          divisor: 1,
        },
      };
      await Promise.all(dataSensor.map(async (row) => {
        formulas.push({
          symbol: 's'.concat(row.Id.toString()),
          formula_text: '=s'.concat(row.Id.toString()).concat(';'),
          output_aggregation: 'AVG',
        });
        sensor_selection.push({
          symbol: 's'.concat(row.Id.toString()),
          sensor_id: row.Id,
          input_aggregation: 'AVG',
        });
      }));

      const widgetSettingsForLastValue = {
        formulas,
        // eslint-disable-next-line camelcase
        input_filter: [input_filter],
        input_time_axis,
        output_time_axis,
        sensor_selection,
        vector_selection: [],
      };

      const data = await (await api.post('SensorLastValue', widgetSettingsForLastValue)).data;

      if (data) {
        const ts = datehandling.utcToZonedTime(data.Rows[0][0]);
        if (!timestamp || ts > timestamp) {
          // eslint-disable-next-line
          timestamp = ts;
        }
        dataSensor = dataSensor.map((row) => {
          // Find the corresponding column in the data datatable

          const columnName = `s${row.Id}`;
          const matchingColumn = data.Columns.findIndex((col) => col.ColumnName === columnName);

          // Add the LastValue property if a match is found
          return {
            ...row,
            LastValue: data.Rows[0][matchingColumn] ? Number(data.Rows[0][matchingColumn]).toFixed(3) : null, // Add the value
          };
        });

        dataSensor.forEach((sensor) => {
          const sensorAlertState = sensorAlertStates.find((x) => x.SensorId === sensor.Id);
          let style = '';
          if (sensorAlertState?.IsAlertState && !sensorAlertState?.HasMissingData) {
            const textColor = textHandling
              .getTextColorDependingOnBackgroung(sensorAlertState.AlertColor);
            const backgroundColor = sensorAlertState.IsMissingData
              ? colorMissingData
              : sensorAlertState.AlertColor;
            style = `style="background-color: ${backgroundColor}; color: ${textColor};"`;
          }

          spanRows += `<tr><td style="color: #a0a0a0;">${sensor.Name}:</td>
            <td class="px-2" ${style}>${sensor.LastValue ?? 'N/A'}</td></tr>`;
        });
      }

      if (spanRows) {
        spanRows += '</table>';
      }

      if (timestamp) {
        popupContent = popupContent
          .concat(`<span class="d-block subtitle-2 mb-5">${datehandling.formatForDisplay(timestamp)}</span>`);
      }

      popupContent = popupContent.concat(spanRows || '<span>No sensor</span>');
      marker.setPopupContent(popupContent);
    },

    getSelectedBaseLayerName(baseMaps) {
      const els = document.getElementsByClassName('leaflet-control-layers-selector');
      for (let i = 0; i < els.length; i += 1) {
        if (els[i].getAttribute('checked')) {
          // eslint-disable-next-line no-underscore-dangle
          const found = Object.entries(baseMaps).find((x) => x[1]._leaflet_id === els[i].layerId);
          setTimeout(() => { if (els[i]) els[i].click(); }, 300);
          if (this.widgetSettings.user?.mapOptions?.defaultLayer && found
            && this.widgetSettings.user.mapOptions.defaultLayer === found[0]) {
            return found;
          }
        }
      }
      return null;
    },

    onBaselayerchange(layerId, baseMaps) {
      this.deselectAllMarkers();
      // eslint-disable-next-line no-underscore-dangle
      const found = Object.entries(baseMaps).find((x) => x[1]._leaflet_id === layerId);
      if (found !== undefined) {
        const [name, layer] = found;
        this.selectedBaseLayerName = name;
        this.selectedBaseLayer = layer;
        this.processInstrumentData(this.instruments, null);
      }
    },

    onBoxSelectEnd(event) {
      this.deselectAllMarkers();

      event.containedMarkers.forEach((marker) => {
        marker.setIcon(getLayerIcon(marker.options.data, 'selected', this.layerNames));
      });

      this.$store
        .commit('instrument/select',
          event.containedMarkers
            .map((x) => x.options.data));
    },

    selectedInstrumentsChanged(event) {
      const { L } = window;

      this.map.eachLayer((l) => {
        if (l && l instanceof L.Marker && l.options.data) {
          if (event.find((x) => x.InstrumentId === l.options.data.InstrumentId)) {
            l.setIcon(this.getLayerIcon(l.options.data, 'selected', this.layerNames));
          } else {
            l.setIcon(this.getLayerIcon(l.options.data, 'normal', this.layerNames));
          }
        }
      });
    },

    zoomToDefaultBounds() {
      this.map.fitBounds(this.defaultBounds);
    },

    instrumentSetLocation() {
      this.isLocationMode = true;
      this.map.on('click', this.onMapClick);
      this.$store.commit('dashboardWidget/visualisationSettingsAllowed', false);
    },
    onMapClick(event) {
      let { lat } = event.latlng;
      let { lng } = event.latlng;

      if (this.isWorldMap) {
        const { bounds } = this.selectedMapView;

        lat = lat > bounds.northEast.lat ? bounds.northEast.lat : lat;
        lat = lat < bounds.southWest.lat ? bounds.southWest.lat : lat;

        // Find corresponding lng in the central section
        while (lng > bounds.northEast.lng || lng < bounds.southWest.lng) {
          lng -= (2 * (lng < 0 ? bounds.southWest.lng : bounds.northEast.lng));
        }
      } else {
        // TODO this.selectedBaseLayer est toujours null, mais il est setté dans onBaselayerchange
        const indexBaselayer = this.selectedMapView?.ImageOverlays
          .findIndex((x) => x.layerGroup === this.selectedBaseLayerName) || 0;
        const bounds = this.selectedMapView?.ImageOverlays[indexBaselayer].bounds;
        lat = lat > bounds[1][0] ? bounds[1][0] : lat;
        lat = lat < bounds[0][0] ? bounds[0][0] : lat;
        lng = lng > bounds[1][1] ? bounds[1][1] : lng;
        lng = lng < bounds[0][1] ? bounds[0][1] : lng;
      }

      lat = Math.round(lat * 100000) / 100000;
      lng = Math.round(lng * 100000) / 100000;

      this.instrumentLocationDialog.latitude = lat;
      this.instrumentLocationDialog.longitude = lng;
      this.$nextTick(() => {
        this.instrumentLocationDialog.show = true;
      });
    },
    async onAddInstrumentSubmit(value) {
      if (this.isWorldMap) {
        const payload = {
          InstrumentId: value.instrument.Id,
          Latitude: parseFloat(value.latitude),
          Longitude: parseFloat(value.longitude),
        };
        await api.post('DevInstrumentRelocate', payload)
          .then(() => {
            this.instrumentLocationDialog.show = false;
            this.$store.dispatch('map/listInstruments').then(async () => {
              await this.init(this.$refs.map, this.selectedMapView).then(() => {
                this.map.on('click', this.onMapClick);
              });
            });
          });
      } else {
        const index = this.selectedMapView?.ImageOverlays.findIndex(
          (x) => x.layerGroup === this.selectedBaseLayerName,
        );
        const payload = {
          InstrumentId: value.instrument.Id,
          Latitude: parseFloat(value.latitude),
          Longitude: parseFloat(value.longitude),
          MapId: this.selectedMapView.ImageOverlays[index].baseLayerId,
        };
        await api.post('DevInstrumentRelocateForBaseMaps', payload)
          .then(() => {
            this.instrumentLocationDialog.show = false;
            this.$store.dispatch('map/listInstruments').then(async () => {
              await this.init(this.$refs.map, this.selectedMapView).then(() => {
                this.map.on('click', this.onMapClick);
              });
            });
          });
      }
    },
    cancelLocationMode() {
      this.$store.commit('dashboardWidget/activeWidgetId', null);
      this.map.off('click', this.onMapClick);
      this.isLocationMode = false;
      this.instrumentLocationDialog.show = false;
      this.$store.commit('dashboardWidget/visualisationSettingsAllowed', true);
    },
  },
};
</script>

<style scoped>
.menu-content {
  max-height: 80vh; /* Adjust the value as needed */
}

.v-select ::v-deep .v-select__selections  input {
  display: none;
}

.leaflet-control-layers{
  top: 25px !important; /* To not superpose with the + and - (zoom button)*/
}
</style>
