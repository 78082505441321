<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ConfirmDialog
      v-model="dialog.Show"
      :message="dialog.Message"
      :no-cancel="dialog.NoCancel"
      :title="dialog.Title"
      :accept-color="dialog.acceptColor"
      @on-accept="dialog.acceptCallback()"
      @on-cancel="dialog.cancelCallback()"
    />
    <AddItemDialog
      v-model="addItemDialog.Show"
      :item-type="addItemDialog.itemTypeChoices"
      @on-accept="(itemName, itemType) => addItemDialog.acceptCallback(itemName, itemType)"
    />
    <RenameDialog
      v-model="renameItemDialog.Show"
      @on-accept="(newNAme) => renameItemDialog.acceptCallback(newNAme)"
    />
    <FilesExplorer
      :grid-source="sources.dataField"
      :show-new-button="false"
      :toolbar-buttons="toolbarButtons"
      :file-tree="fileTree"
      :path="path"
      :row-data="currentRowData"
      :edit-mode="editMode"
      :filename="filename"
      :file-content="fileContent"
      @update-path="updatePath"
      @change-path="changePath"
      @update-edit-mode="updateEditMode"
      @create-item="createItem"
      @save-file="saveFile"
      @update-file-content="updateFileContent"
      @update-filename="updateFilename"
      @save-dropped-file="saveDroppedFile"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FilesExplorer from './FilesExplorer.vue';
import GsFilesTreeNode from '../gridsource/gsFilesTreeNode';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import AddItemDialog from './AddItemDialog.vue';
import RenameDialog from './RenameDialog.vue';

export default {
  components: {
    FilesExplorer,
    ConfirmDialog,
    AddItemDialog,
    RenameDialog,
  },
  props: {
    modemMac: {
      type: String,
      require: true,
      default: '',
    },
    initPath: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  data() {
    return {
      sources: {
        dataField: null,
      },
      rowData: [],
      toolbarButtons: [],
      editMode: false,
      dialog: {
        Message: '',
        NoCancel: false,
        Title: '',
        Show: false,
        acceptCallback: null,
        cancelCallback: null,
        acceptColor: null,
      },
      addItemDialog: {
        Show: false,
        itemTypeChoices: ['file', 'folder'],
        acceptCallback: null,
      },
      renameItemDialog: {
        Show: false,
        acceptCallback: null,
      },
    };
  },
  computed: {
    ...mapState('worker/modemFileTree', ['filename', 'fileContent', 'fileTree', 'path', 'currentRowData']),
  },
  created() {
    this.sources.dataField = new GsFilesTreeNode(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];
    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => {
        this.refreshList();
      },
    });
  },
  beforeDestroy() {
    this.updatePath([this.path[0]]);
  },
  methods: {
    // **************** Store Controls ****************
    refreshList() {
      this.$store.dispatch('worker/modemFileTree/fetchTree', { modemMacAddress: this.modemMac });
    },
    refreshListNoWait() {
      this.$store.dispatch('worker/modemFileTree/fetchTreeNoWait', { modemMacAddress: this.modemMac });
    },
    saveDroppedFile() {
      this.$store.dispatch('worker/modemFileTree/sendDroppedFile');
    },
    updateFilename(filename) {
      this.$store.commit('worker/modemFileTree/filename', filename);
    },
    updateFileContent(content) {
      this.$store.commit('worker/modemFileTree/fileContent', content);
    },
    // **************************************************** //

    // **************** Path Control ***************
    changePath(itemName) {
      const newNode = this.currentRowData.find((node) => (node.name === itemName));
      if (newNode && newNode.type === 'file') {
        this.$store.commit('worker/modemFileTree/filename', itemName);
      } else this.$store.commit('worker/modemFileTree/filename', '');
      this.path.push(itemName);
      this.updatePath(this.path);
    },
    updatePath(newPath) {
      this.$store.commit('worker/modemFileTree/updatePath', newPath);
      this.$store.commit('worker/modemFileTree/updateRowData');
    },
    // *********************************************** //

    // **************** Bouttons Controls ****************
    createItem() {
      this.addItemDialog.Show = true;
      this.addItemDialog.acceptCallback = (itemName, itemType) => {
        if (this.currentRowData.find((node) => node.name === itemName)) {
          this.showWarning(this.$t('Worker.ModemFileTree.Warning.ItemPresent'), () => {}, () => {}, true);
          return;
        }
        this.$store.dispatch('worker/modemFileTree/createItem', { itemName, itemType });
      };
    },
    editFile(editedFilename) { // used in gsFileTreeNode
      this.updateEditMode(true);
      this.changePath(editedFilename);
      this.$store.dispatch('worker/modemFileTree/getFile');
    },
    renameItem(name, type) { // used in gsFileTreeNode
      this.renameItemDialog.Show = true;
      this.renameItemDialog.acceptCallback = (newName) => {
        if (this.currentRowData.find((node) => node.name === newName)) {
          this.showWarning(this.$t('Worker.ModemFileTree.Warning.ItemPresent'), () => {}, () => {}, true);
          return;
        }
        this.$store.dispatch('worker/modemFileTree/renameItem', { newName, name, type });
      };
    },
    deleteItem(name, type) { // used in gsFileTreeNode
      this.showWarning(`${this.$t('Worker.ModemFileTree.Warning.Deletion')}"${name}"`,
        () => {
          const nodeToDelete = this.currentRowData.find((node) => node.name === name);
          if (nodeToDelete.type === 'folder' && nodeToDelete.items.length > 0) {
            this.showWarning(this.$t('Worker.ModemFileTree.Warning.FolderNotEmpty'), () => {}, () => {}, true);
            return;
          }
          this.$store.dispatch('worker/modemFileTree/deleteItem', { name, type });
        });
    },

    saveFile() {
      this.$store.dispatch('worker/modemFileTree/sendFile');
    },
    // **************************************************** //

    updateEditMode(newState) {
      this.editMode = newState;
    },

    showWarning(message, acceptCallback = () => {}, cancelCallback = () => {}, NoCancel = false) {
      this.dialog.Message = message;
      this.dialog.NoCancel = NoCancel;
      this.dialog.Title = this.$t('Settings.Dialog.Warning');
      this.dialog.acceptCallback = acceptCallback;
      this.dialog.cancelCallback = cancelCallback;
      this.dialog.Show = true;
      this.dialog.acceptColor = 'red';
    },
  },
};
</script>
