import ModemFilesList from './components/ModemFilesList.vue';

export default [
  {
    path: '/Communication/ModemFilesTree/:id',
    name: 'Worker.Modem.TreeFile',
    component: ModemFilesList,
    props: (route) => ({ modemMac: route.params.id }),
    children: [],
  },
];
