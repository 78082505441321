import WorkersRoutes from './workers/workers.routes';
import ModemRoutes from './modem/modem.routes';
import ModemRoutesTree from './modemFileTree/modemFileTree.route';
import WorkerConfigsRoutes from './workerConfigurations/workerconfigs.routes';

export default [
  ...WorkersRoutes,
  ...ModemRoutes,
  ...ModemRoutesTree,
  ...WorkerConfigsRoutes,
];
