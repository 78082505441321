<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    @keydown="handleKeyDown"
  >
    <v-card>
      <v-card-title>
        {{ $t('Worker.ModemFileTree.CreateItem') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="textFieldValue"
          :label="$t('Worker.ModemFileTree.Filename')"
          :error="hasErrorTextField"
          :class="{'shake-error': hasErrorTextField}"
          @animationend="resetError"
        />
        <v-select
          v-model="selectedType"
          :items="itemType"
          :label="$t('Worker.ModemFileTree.ItemType')"
          :error="hasErrorTypeField"
          :class="{'shake-error': hasErrorTypeField}"
          @animationend="resetError"
        />
      </v-card-text>
      <v-card-actions style="justify-content: right">
        <v-btn @click.native="onCancel">
          {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Cancel') }}
        </v-btn>
        <v-btn @click.native="onAccept">
          {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    itemType: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedType: null,
      textFieldValue: '',
      hasErrorTextField: false,
      hasErrorTypeField: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onAccept() {
      if (this.validateInputtextField() && this.validateInputType()) {
        this.show = false;
        this.$emit('on-accept', this.textFieldValue, this.selectedType);
        this.resetField();
      }
    },
    onCancel() {
      this.show = false;
      this.resetField();
      this.$emit('on-cancel');
    },
    resetField() {
      this.textFieldValue = '';
      this.selectedType = null;
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.onAccept();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        this.show = false;
      }
    },
    validateInputtextField() {
      if (this.textFieldValue === null || this.textFieldValue.length === 0) {
        this.hasErrorTextField = true;
        setTimeout(() => {
          this.hasErrorTextField = false;
        }, 1500);
        return false;
      }
      return true;
    },
    validateInputType() {
      if (this.selectedType === null) {
        this.hasErrorTypeField = true;
        setTimeout(() => {
          this.hasErrorTypeField = false;
        }, 1500);
        return false;
      }
      return true;
    },
    resetError() {
      this.hasErrorTextField = false;
      this.hasErrorTypeField = false;
    },
  },
};

</script>

<style scoped>
.positioned-alert {
  z-index: 9000; /* Adjust to be higher than the dialog */
}

.shake-error {
  animation: shake 0.3s ease-in-out;
  border: 2px solid red !important;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}
</style>
