<template>
  <div
    style="width: 100%; height: 100%"
    @drop="onDrop"
    @dragover="(event) => event.preventDefault()"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
  >
    <v-overlay
      v-if="!editMode"
      :value="isDragging"
      absolute
      style="z-index: 200; position: absolute;"
    >
      <div class="overlay-content">
        <v-icon
          large
          color="white"
        >
          mdi-file-upload
        </v-icon>
        <p>Drop file here</p>
      </div>
    </v-overlay>

    <SaveFileDialog
      v-model="dialog.Show"
      :message="dialog.Message"
      :no-cancel="dialog.NoCancel"
      :title="dialog.Title"
      @on-save="dialog.onSave()"
      @on-no-save="dialog.onNoSave()"
    />
    <v-toolbar
      v-if="showToolbar || toolbarFields.length"
      dense
      :height="toolbarFields.length ? 50 : undefined"
    >
      <v-toolbar-items
        v-for="(item, index) in toolbarContent"
        :key="index"
      >
        <toolbar-button-with-tooltip
          v-if="item.enabled"
          :tooltip-text="item.tooltip"
          :icon-name="item.icon"
          :rotation="item.rotation"
          @click="item.click"
        />
      </v-toolbar-items>
      <v-toolbar-items
        v-for="(item, index) in toolbarFields"
        :key="`${item.label}_${index}`"
      >
        <search-text-field
          v-if="item.fieldType === fieldTypes.SearchTextField"
          :value="item.value"
          :label="item.label"
          :tooltip="item.tooltip"
          :disabled="item.disabled"
          :width="item.width"
          :on-searched="item.onSearched"
          class="mt-4"
        />
        <select-field
          v-else-if="item.fieldType === fieldTypes.SelectField"
          :value="item.value"
          :label="item.label"
          :items="item.items"
          :item-text="item.itemText"
          :disabled="item.disabled"
          :width="item.width"
          :multiple="item.multiple"
          :on-changed="item.onChanged"
          :on-clear="item.onClear"
          class="mt-4"
        />
        <select-with-search
          v-else-if="item.fieldType === fieldTypes.SelectWithSearch"
          :value="item.value"
          :search-value="item.searchValue"
          :label="item.label"
          :items="item.items"
          :item-text="item.itemText"
          :has-more-items="item.hasMoreItems"
          :error="item.error"
          :disabled="item.disabled"
          :width="item.width"
          :on-changed="item.onChanged"
          :on-searched="item.onSearched"
          :on-more="item.onMore"
          class="mt-2 mx-2"
        />
      </v-toolbar-items>
      <toolbar-file-path
        v-if="path.length > 0"
        :path="path"
        :is-last-path-a-file="isLastPathAFile"
        @update-path="updatePath"
      />
    </v-toolbar>
    <ag-grid-vue
      v-if="!editMode"
      :style="{ width: '100%', height: gridSource.height || '100%' }"
      class="ag-theme-balham-dark"
      :column-defs="columnDefs"
      :row-data="rowData"
      :default-col-def="defaultColDef"
      :suppress-row-click-selection="true"
      :row-selection="rowSelection"
      :locale-text="localeText"
      :pagination="pagination"
      accented-sort="true"
      suppress-movable-columns="true"
      @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
    />
    <MonacoEditor
      :filename="filename"
      :value="fileContent"
      :disable="!editMode"
      @input="updateFileContent"
    />
    <input
      ref="uploadFileInput"
      type="file"
      style="display: none"
      @change="onUploadFile"
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import agGridFr from '@/locales/agGridFr';
import SaveFileDialog from './SaveFileDialog.vue';
import DeleteButtonIdCellRenderer from '@/components/DeleteButtonIdCellRenderer.vue';
import InfoButtonStringCellRenderer from '@/components/InfoButtonStringCellRenderer.vue';
import IconButtonCellRenderer from '@/components/IconButtonCellRenderer.vue';
import EditButtonCellRenderer from '@/components/EditButtonCellRenderer.vue';
import ReadonlyDateTimeCellRenderer from '@/components/ReadonlyDateTimeCellRenderer.vue';
import EditableDateTimeCellRenderer from '@/components/EditableDateTimeCellRenderer.vue';
import UtcDateTimeCellRenderer from '@/components/UtcDateTimeCellRenderer.vue';
import NonNullObjectCountCellRenderer from '@/components/NonNullObjectCountCellRenderer.vue';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import ToolbarFilePath from './ToolbarFilePath.vue';
import UserButtonCellRenderer from '@/components/UserButtonCellRenderer.vue';
import SelectField from '@/components/SelectField.vue';
import SearchTextField from '@/components/SearchTextField.vue';
import SelectWithSearch from '@/components/SelectWithSearch.vue';
import FieldTypesEnum from '@/components/FieldTypesEnum';
import MonacoEditor from './MonacoEditor.vue';

export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    DeleteButtonIdCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    IconButtonCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    InfoButtonStringCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    EditButtonCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    EditableDateTimeCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ReadonlyDateTimeCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    UtcDateTimeCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    NonNullObjectCountCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    UserButtonCellRenderer,
    ToolbarButtonWithTooltip,
    ToolbarFilePath,
    SaveFileDialog,
    SelectField,
    SelectWithSearch,
    SearchTextField,
    MonacoEditor,
  },
  props: {
    refresh: {
      type: Number,
      default: () => 0,
    },
    fileTree: {
      type: Object,
      default: () => null,
    },
    gridSource: {
      type: Object,
      required: true,
    },
    showNewButton: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    toolbarButtons: {
      type: Array,
      default: () => [],
      required: false,
    },
    toolbarFields: {
      type: Array,
      default: () => [],
      required: false,
    },
    enableSelection: {
      type: String, // 'single', 'multiple'
      default: () => undefined,
      required: false,
    },
    pagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    validatePopupValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    path: {
      type: Array,
      required: true,
    },
    rowData: {
      type: Array,
      require: true,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    filename: {
      type: String,
      default: () => '',
    },
    fileContent: {
      type: String,
      default: () => '',
    },
  },
  emits: ['selection-changed', 'length-changed'],
  setup() {},
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      popup: {
        Show: false,
        Model: {},
        Schema: {},
      },
      rowSelection: null,
      SingleSelection: false,
      localeText: null,
      dialog: {
        Message: '',
        NoCancel: false,
        Title: '',
        Show: false,
        onSave: null,
        onNoSave: null,
      },
      fieldTypes: FieldTypesEnum,
      editor: null,
      fileSaved: true,
      isDragging: false,
      dragCounter: 0,
    };
  },
  computed: {
    ...mapState('worker/modemFileTree', ['isLastPathAFile']),
    defaultColDef() {
      return {
        resizable: true,
        headerCheckboxSelection: this.isSingleSelectionEnabled,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: this.isSelectionEnabled,
        filterParams: { newRowsAction: 'keep', buttons: ['reset', 'apply'] },
      };
    },
    showToolbar() {
      return this.toolbarContent.length > 0;
    },
    toolbarContent() {
      const tbar = [];

      if (this.toolbarButtons.length > 0) {
        this.toolbarButtons.forEach((x) => {
          tbar.push(x);
        });
      }
      tbar.push({
        tooltip: this.$t('AddNew'),
        icon: 'mdi-plus',
        enabled: !this.editMode,
        click: () => {
          this.onNewButtonClick();
        },
      });
      tbar.push({
        tooltip: this.$t('Worker.ModemFileTree.UploadFile'),
        icon: 'mdi-attachment',
        enabled: !this.editMode,
        rotation: 270,
        click: () => {
          this.onUploadFileButtonClick();
        },
      });
      tbar.push({
        tooltip: this.$t('Save'),
        icon: 'mdi-content-save',
        enabled: this.editMode,
        click: () => {
          this.onSaveButtonClick();
        },
      });

      return tbar;
    },
  },
  watch: {
    async refresh() {
      if (!this.editMode) {
        await this.initColumnsDefinitions();
      }
    },
  },
  created() {
    if (this.$t.locale === 'fr') {
      this.localeText = agGridFr.locale_fr;
    }
    if (this.enableSelection !== undefined) {
      this.rowSelection = this.enableSelection;
      this.SingleSelection = true;
    }
    if (this.enableSelection === 'single') {
      this.SingleSelection = false;
    }
  },
  async beforeMount() {
    await this.initColumnsDefinitions();
  },
  methods: {
    // **************** Grid Control ****************
    async initColumnsDefinitions() {
      this.columnDefs = await this.gridSource.columnDefinitions();
    },
    isSelectionEnabled(params) {
      return this.isFirstColumn(params) && this.enableSelection;
    },
    isSingleSelectionEnabled(params) {
      return this.isFirstColumn(params) && this.SingleSelection;
    },
    isFirstColumn(params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns();
      const thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.$emit('grid-ready', params);
    },
    onSelectionChanged(event) {
      this.$emit('selection-changed', event);
    },
    // ************************************************ //

    // **************** Boutons Control ***************
    async onNewButtonClick() {
      this.$emit('create-item');
    },
    async onSaveButtonClick() {
      this.fileSaved = true;
      this.$emit('save-file');
    },
    async onUploadFileButtonClick() {
      this.$refs.uploadFileInput.click();
    },
    // *********************************************** //

    showWarning(message, onSave, onNoSave) {
      this.dialog.Message = message;
      this.dialog.NoCancel = false;
      this.dialog.Title = this.$t('Settings.Dialog.Warning');
      this.dialog.onSave = onSave;
      this.dialog.onNoSave = onNoSave;
      this.dialog.Show = true;
    },

    updateEditMode(newState) {
      this.$emit('update-edit-mode', newState);
    },

    // **************** Path Control ***************
    updatePath(newPath) {
      const innerUpdatePath = () => {
        this.updateEditMode(false);
        this.fileSaved = true;
        this.$emit('update-filename', '');
        this.$emit('update-file-content', '');
        this.$emit('update-path', newPath);
      };
      if (this.editMode && !this.fileSaved) {
        this.showWarning(this.$t('Worker.ModemFileTree.Warning.SaveFile'),
          () => {
            this.onSaveButtonClick();
            innerUpdatePath();
          },
          () => { innerUpdatePath(); });
      } else innerUpdatePath();
    },
    changePath(newItem) {
      this.$emit('change-path', newItem);
    },
    // *********************************************** //

    // **************** Send File Control ***************
    updateFileContent(newContent) {
      this.fileSaved = false;
      this.$emit('update-file-content', newContent);
    },
    onDragEnter(event) {
      if (!this.editMode) {
        event.preventDefault();
        this.dragCounter += 1;
        this.isDragging = true;
      }
    },
    onDragLeave(event) {
      if (!this.editMode) {
        event.preventDefault();
        this.dragCounter -= 1;
        if (this.dragCounter === 0) {
          this.isDragging = false;
        }
      }
    },
    onDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      this.dragCounter = 0; // Reset counter
      if (!this.editMode) {
        const { files } = event.dataTransfer;
        if (files.length > 0) {
          const file = files[0];
          this.sendFile(file);
        }
      }
    },
    onUploadFile(event) {
      const file = event.target.files[0];
      if (file) {
        this.sendFile(file);
      }
    },
    sendFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result.replace(/\r\n|\r/g, '\n');
        this.$emit('update-filename', file.name);
        this.$emit('update-file-content', fileContent);
        this.$emit('save-dropped-file');
      };
      reader.readAsText(file);
    },
    // ************************************************ //
  },
};
</script>

<style scoped>
.drop-zone {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>
