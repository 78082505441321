<template>
  <div
    v-if="!disable"
    ref="editorContainer"
    :style="{width: '100%', height: '100%'}"
  />
</template>

<script>
import * as monaco from 'monaco-editor';

export default {
  name: 'MonacoEditor',
  props: {
    value: {
      type: String,
      default: () => '',
    },
    disable: {
      type: Boolean,
      default: true,
    },
    filename: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    value(newValue) {
      if (this.editor && this.editor.getValue() !== newValue) {
        this.editor.setValue(newValue);
      }
    },
    disable(newValue) {
      if (newValue) {
        this.disposeEditor();
      } else {
        this.$nextTick(() => {
          this.initializeEditor();
        });
      }
    },
    filename(newValue) {
      if (this.editor) {
        const model = this.editor.getModel();
        if (model) { // Ensure model exists
          const language = newValue.startsWith('settings.') ? 'ini' : 'plaintext';
          monaco.editor.setModelLanguage(model, language);
        }
      }
    },
  },
  mounted() {
    if (!this.disable) { // Initialize editor only if not disabled
      this.$nextTick(this.initializeEditor);
    }
  },
  beforeDestroy() {
    this.disposeEditor();
  },
  methods: {
    initializeEditor() {
      if (this.$refs.editorContainer) {
        monaco.languages.register({ id: 'ini' });
        monaco.languages.register({ id: 'plaintext' });

        this.editor = monaco.editor.create(this.$refs.editorContainer, {
          value: this.value.replace(/\r\n|\r/g, '\n'),
          language: 'plaintext',
          parameterHints: true,
          automaticLayout: true,
        });

        const model = this.editor.getModel();
        if (model) {
          const language = this.filename.startsWith('settings.') ? 'ini' : 'plaintext';
          monaco.editor.setModelLanguage(model, language);
          model.setEOL(monaco.editor.EndOfLineSequence.LF);
        }

        this.editor.onDidChangeModelContent(() => {
          const value = this.editor.getValue().replace(/\r\n|\r/g, '\n');
          if (this.value !== value) {
            this.$emit('input', value);
          }
        });
      }
    },
    disposeEditor() {
      if (this.editor) {
        this.editor.dispose();
        this.editor = null;
      }
    },
  },
};
</script>

<style scoped>
/* Optional: Add custom styles for the editor container */
</style>
