<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.modem"
      :show-new-button="false"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="modems"
    />
    <router-view />
    <v-dialog
      v-model="showJournal"
      max-width="1000px"
    >
      <template v-if="showJournal">
        <ServerSideDataGrid
          ref="journalGrid"
          :grid-source="sources.journal"
          :show-new-button="false"
          :row-data-init="journalData"
          style="width: 100%; height: 400px"
        />
      </template>
    </v-dialog>
    <v-dialog
      v-if="showAGDialog"
      v-model="AGDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('Devices.Icons.ChangeAccessGroup') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedItem"
            :items="items"
            :label="$t('Devices.Gateway.AddNew.AccessGroup')"
            item-text="AccessGroupName"
            item-value="AccessGroupId"
          />
        </v-card-text>
        <v-card-actions style="justify-content: right">
          <v-btn @click="closeDialog">
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Cancel') }}
          </v-btn>
          <v-btn @click="confirmSelection">
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showLogDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Worker.Modem.LogFileContent') }}
        </v-card-title>
        <v-card-text>
          <pre>{{ logContent }}</pre>
        </v-card-text>
        <v-card-actions style="justify-content: right">
          <v-btn
            color="primary"
            @click="closeLogDialog"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ModemList from '../gridsources/gsmodemconfigs';
import JournalTable from '../gridsources/gsjournal';
import importal from '@/api/importal';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        modem: null,
        journal: null,
      },
      items: null,
      toolbarButtons: [],
      showConfirm: false,
      editModel: null,
      showAGDialog: false,
      AGDialog: null,
      selectedItem: null,
      item: [],
      selectedModem: null,
      showJournal: false,
      journalData: null,
      toolbarButtonsForJournal: [],
      showLogDialog: false,
      logContent: null,
    };
  },
  computed: {
    ...mapState('worker/modem', ['modems']),
  },
  // watch: {
  //   async tenantId() {
  //     this.refreshList();
  //   },
  //   async accessGroupIds(newVal, oldVal) {
  //     if (oldVal.join() !== newVal.join()) {
  //       this.refreshList();
  //     }
  //   },
  // },
  created() {
    this.sources.modem = new ModemList(this, {
      changeAG: (data) => this.changeAG(data),
      onSeeJournal: (data) => this.onSeeJournal(data),
      onSeeTree: (data) => this.onSeeTree(data),
    });
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];
    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => {
        this.refreshList();
      },
    });
  },
  methods: {
    refreshList() {
      this.$store.dispatch('worker/modem/list');
    },
    async changeAG(data) {
      this.selectedModem = data.Uid;
      this.showAGDialog = true;
      this.items = await (await importal.get('AccessGroupList')).data;
      this.AGDialog = true;
    },
    closeDialog() {
      this.showAGDialog = false;
    },
    async confirmSelection() {
      this.showAGDialog = false;
      await importal.post('ModemApiModemChangeAG', {
        ModemId: this.selectedModem,
        AccessGroupId: this.selectedItem,
      });

      this.selectedRows = [];
      this.setToolBarButtonsDisabled();

      this.refreshList();
    },
    async onSeeJournal(modem) {
      const data = await (
        await importal.get(`WorkerApiReadCommEquipmentLog?I=${modem.Uid}`)
      ).data;
      if (data) {
        this.journalData = data;
        this.sources.journal = new JournalTable(this, {
          onSeeLog: (data2) => this.onSeeLog(data2),
        });
      }
      this.showJournal = true;
    },
    async onSeeTree(modem) {
      const data = await (
        await importal.get(`WorkerApiReadCommEquipmentLog?I=${modem.Uid}`)
      ).data;
      if (data) {
        this.$router.push(`/Communication/ModemFilesTree/${modem.Name.split('.').at(-1)}`);
      }
    },
    onSeeLog(params) {
      if (params && params.LogFileContent) {
        this.logContent = params.LogFileContent;
        this.showLogDialog = true;
      }
    },
    closeLogDialog() {
      this.showLogDialog = false;
      this.logContent = null;
    },
  },
};
</script>
