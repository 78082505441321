<template #activator="{ on, attrs }">
  <div class="folder-path">
    <div
      v-for="(folder, index) in path"
      :key="index"
      class="folder-container"
    >
      <v-btn
        v-if="index < path.length - 1"
        text
        style="text-transform: none !important;"
        @click="navigateTo(index)"
      >
        <v-icon class="mx-2">
          {{ 'mdi-folder' }}
        </v-icon>
        {{ folder }}
      </v-btn>
      <v-btn
        v-if="index == path.length - 1"
        text
        class="custom-disabled"
      >
        <v-icon
          v-if="!isLastPathAFile"
          class="mx-2"
        >
          {{ 'mdi-folder-open' }}
        </v-icon>
        <v-icon
          v-if="isLastPathAFile"
          class="mx-2"
        >
          {{ 'mdi-file-document' }}
        </v-icon>
        {{ folder }}
      </v-btn>
      <span v-if="index < path.length - 1">{{ separator }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: Array,
      required: true,
    },
    separator: {
      type: String,
      default: () => '/',
    },
    isLastPathAFile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigateTo(index) {
      this.$emit('update-path', this.path.slice(0, index + 1));
    },
  },
};
</script>

<style>
.folder-path {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.folder-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.custom-disabled {
  pointer-events: none;
  cursor: not-allowed;
  text-transform: none !important;
}
</style>
